/* eslint-disable camelcase */
import React from 'react'
import { Map as L_Map } from 'leaflet'
import PropTypes from 'prop-types'
import { Segment } from 'semantic-ui-react'

import Indicators from '../../files/palau.geojson'
import PAs from '../../files/palau_pa.topojson'
import Profile from '../../images/palau.jpg'
import IndicatorsSummary from '../../files/MCSummary_Palau.pdf'

import Base from './Base'
import { OVERLAYS_ATTRS } from '../Map'

const Props = {
    viewName: 'palau',
    header: 'Republic of Palau',
    profileUrl: Profile,
    partners: [
        {
            label: 'Palau Bureau of Agriculture',
            url: 'http://palaugov.pw/executive-branch/ministries/natural-resources/bureau-of-agriculture/'
        },
        {
            label: 'Belau National Museum',
            url: 'http://belaunationalmuseum.net/'
        },
        {
            label: 'Coral Reef Research Foundation',
            url: 'http://coralreefpalau.org/'
        },
        {
            label: 'Palau Office of Environmental Response and Coordination',
            url: 'http://palau.chm-cbd.net/oerc-environmetal-response-coordination'
        },
        {
            label: 'Koror State Government',
            url: 'http://kororstategov.com/'
        },
        {
            label: 'Palau Automated Land and Resource Information System',
            url: 'http://palau.chm-cbd.net/palaris-land-and-resources-gis'
        },
        {
            label: 'Palau protected area network',
            url: 'http://www.palaupanfund.org/'
        },
        {
            label: 'Palau International Coral Reef Center',
            url: 'http://picrc.org/picrcpage/ '
        },
        {
            label: 'Palau Conservation Society',
            url: 'http://palauconservation.org/'
        }
    ],
    summaryContent: (
        <Segment basic>
            <p>
                The Republic of Palau is known for its incredible diversity of marine and terrestrial resources along a network of more than 700 islets and 12 islands. The government of Palau has engaged with state governments and traditional leaders to establish a Protected Areas Network to manage and conserve critical resources. Each state is working with the Palauan government to monitor and report on the status of these protected areas.
            </p>
            <p>
                Palau’s forest monitoring program includes the following efforts:
            </p>
            <ul>
                <li>Decadal measurements of a regional network of permanent Forest Inventory and Analysis (FIA) plots</li>
                <li>Permanent mangrove monitoring plots</li>
                <li>State assessments of forest health</li>
                <li>Conservation Network Forest Dynamic Monitoring plots in the Ngardok Nature Reserve</li>
                <li>Invasive species detection and mapping</li>
                <li>Forest dynamic monitoring through remote sensing</li>
            </ul>
            <p>
                Palau is expanding the frequency and intensity of monitoring in each state to capture additional terrestrial changes and to further meet the goals laid out by the Micronesia Challenge Terrestrial Measures Working Group.
            </p>
            <b>
                Forest Resources in Babeldoab and the Rock Islands
            </b>
            <br /><br />
            <p>
                This map summarizes data collected in 2003 and 2014 from FIA plots systematically established throughout the volcanic forests in Babeldoab and the limestone forests in the Rock Islands. Foresters from Palau’s Bureau of Agricultures Forestry Program conducted the fieldwork, with training and program management assistance from the foresters from the USDA Forest Service Pacific Northwest Research Station and the University of Guam. In 2014, 56 FIA plots were measured in Palau.
            </p>
            <p>
                More information on Palau’s forest monitoring programs are available through these websites:
            </p>
            <ul className="summary-links">
                <li>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.fs.fed.us/pnw/rma/fia-topics/inventory-data/index.php"
                    >
                        The complete Forest Inventory and Analysis database for Palau
                    </a>
                </li>
                <br />
                <li>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://forestgeo.si.edu/sites/palau/ngardok"
                    >
                        Information on the Palau-Pacific Island Permanent Plot Network
                    </a>
                </li>
                <br />
                <li>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="http://palau.chm-cbd.net/invasive-species-palau"
                    >
                        Invasive Species Monitoring - CHM
                    </a>
                </li>
                <br />
                <li>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.palaunisc.org/"
                    >
                        Invasive Species Monitoring - ISC
                    </a>
                </li>
                <br />
                <li>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="www.palaupanfund.org"
                    >
                        Palau Protected Areas Network
                    </a>
                </li>
                <br />
                <li>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.coris.noaa.gov/portals/palau.html#5"
                    >
                        Mangrove monitoring
                    </a>
                </li>
                <br />
            </ul>
        </Segment>
    ),
    indicatorsDataUrl: Indicators,
    indicatorsSummary: IndicatorsSummary,
    layers: [
        Object.assign({
            url: PAs,
            type: 'topojson'
        }, OVERLAYS_ATTRS.pa),
        OVERLAYS_ATTRS.veg('palau')
    ]
}

const Palau = props => (
    <div className="darken-palau"><Base {...Props} map={props.map} /></div>
)

Palau.propTypes = {
    // props
    map: PropTypes.instanceOf(L_Map).isRequired
}

export default Palau
