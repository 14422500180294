export const ACTIONS = {
    SEND_ACTION_TO_MAP: 'SEND_ACTION_TO_MAP',
    RESET_PLOT_VIEW: 'RESET_PLOT_VOEW'
}

export const sendActionToMap = ({ action, args }) => ({
    type: ACTIONS.SEND_ACTION_TO_MAP,
    hash: new Date(),
    action,
    args
})
