/* eslint-disable camelcase */
import React from 'react'
import { Map as L_Map } from 'leaflet'
import PropTypes from 'prop-types'
import { Segment } from 'semantic-ui-react'

import Indicators from '../../files/marshall.geojson'
import PAs from '../../files/marshall_pa.geojson'
import Profile from '../../images/marshall.png'
import IndicatorsSummary from '../../files/MCSummary_Marshall.pdf'

import Base from './Base'
import { OVERLAYS_ATTRS } from '../Map'

const Props = {
    viewName: 'marshall',
    header: 'Republic of the Marshall Islands',
    profileUrl: Profile,
    partners: [
        {
            label: 'RMI Ministry of Natural Resources and Commence',
            url: ''
        },
        {
            label: 'College of the Marshall Islands',
            url: 'http://www.cmi.edu/'
        },
        {
            label: 'Marshall Islands Marine Resources Authority',
            url: 'https://www.mimra.com/'
        },
        {
            label: 'Women United Together Marshall Islands',
            url: 'http://www.wutmi.com/'
        },
        {
            label: 'Marshall Islands Conservation Society (MICS)',
            url: 'https://atollconservation.org/'
        },
        {
            label: 'RMI Ministry of Culture and Internal Affairs',
            url: ''
        }
    ],
    summaryContent: (
        <Segment basic>
            <p>
                The USDA Forest Service Forest Inventory and Analysis Program (FIA) worked with foresters from the Pacific Islands to collect data on the islands’ forests and understory vegetation in 2008. Forest inventory plots were laid out in a grid pattern on the following 10 atolls: Jaluit, Majuro, Kwajalein, Maloelap, Mili, Ailinglaplap, Arno, Likiep, Rongelap, and Wotje.
            </p>
            <p>
                These plot locations were re-measured in 2018. Three more atolls (Mejit, Ailuk and Aur) were added to the inventory as part of the Micronesia Challenge Project which is working to preserve marine and terrestrial resources in many regions of the Pacific. Funding for the additional Micronesia Challenge plots came from a U.S. Forest Service Landscape Restoration Grant.
            </p>
            <p>
                In the 2018, field crew inventoried 85 plots:
            </p>
            <ul>
                <li>
                    65 FIA grid plots
                </li>
                <li>
                    20 Micronesia Challenge plots.
                </li>
            </ul>
            <p>
                This monitoring is scheduled to continue and possibly expand in the future to further meet the monitoring needs of the RMI.
            </p>
            <p>
                More information on RMI’s monitoring programs are available through these websites:
            </p>
            <ul className="summary-links">
                <li>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.fs.fed.us/pnw/rma/fia-topics/inventory-data/index.php"
                    >
                        The complete Forest Inventory and Analysis database for the Republic of the Marshall Islands
                    </a>
                </li>
                <br />
                <li>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="http://www.comfsm.fm/bchm/TerrestrialBio.pdf"
                    >
                        Terrestrial Biodiversity of the FSM
                    </a>
                </li>
                <br />
                <li>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="http://www.reefresilience.org/case-studies/micronesia-mpa-design/"
                    >
                        Designing a Protected Area Network in FSM
                    </a>
                </li>
            </ul>
        </Segment>
    ),
    indicatorsDataUrl: Indicators,
    indicatorsSummary: IndicatorsSummary,
    layers: [
        Object.assign({
            url: PAs,
            type: 'geojson'
        }, OVERLAYS_ATTRS.pa),
        OVERLAYS_ATTRS.veg('marshall')
    ]
}

const Mrashll = props => (
    <div className="darken-marshall"><Base {...Props} map={props.map} /></div>
)

Mrashll.propTypes = {
    // props
    map: PropTypes.instanceOf(L_Map).isRequired
}

export default Mrashll
