import { format } from 'd3'

export const precision = (value, decimals) => format(`.${decimals}f`)(value)

export const percent = (d) => {
    if (d === 0) {
        return '0%'
    }
    if (d < 1) {
        return '<1%'
    }
    if (d >= 1 && d <= 5) {
        return `${precision(d, 1)}%`
    }
    return `${precision(d, 0)}%`
}
