import React from 'react'
import {
    Redirect,
    Route,
    Switch,
    withRouter
} from 'react-router-dom'
import { Segment } from 'semantic-ui-react'

import MapContext from '../MapContext'
import Intro from './Intro'
import Mariana from './Mariana'
import FSM from './FSM'
import Chuuk from './FSM/Chuuk'
import Kosrae from './FSM/Kosrae'
import Pohnpei from './FSM/Pohnpei'
import Yap from './FSM/Yap'
import Guam from './Guam'
import Palau from './Palau'
import Marshall from './Marshall'

const Sidebar = () => (
    <MapContext.Consumer>
        {map => (
            <Segment id="Sidebar" basic>
                <Switch>
                    <Route
                        exact
                        path="/intro"
                        render={() => <Intro map={map} />}
                    />
                    <Route
                        exact
                        path="/mariana"
                        render={() => <Mariana map={map} />}
                    />
                    <Route
                        exact
                        path="/fsm"
                        render={() => <FSM map={map} />}
                    />
                    <Route
                        exact
                        path="/fsm/chuuk"
                        render={() => <Chuuk map={map} />}
                    />
                    <Route
                        exact
                        path="/fsm/kosrae"
                        render={() => <Kosrae map={map} />}
                    />
                    <Route
                        exact
                        path="/fsm/pohnpei"
                        render={() => <Pohnpei map={map} />}
                    />
                    <Route
                        exact
                        path="/fsm/yap"
                        render={() => <Yap map={map} />}
                    />
                    <Route
                        exact
                        path="/guam"
                        render={() => <Guam map={map} />}
                    />
                    <Route
                        exact
                        path="/palau"
                        render={() => <Palau map={map} />}
                    />
                    <Route
                        exact
                        path="/marshall"
                        render={() => <Marshall map={map} />}
                    />
                    <Redirect from="/" to="intro" />
                </Switch>
            </Segment>
        )}
    </MapContext.Consumer>
)

export default withRouter(Sidebar)
