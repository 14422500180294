/* eslint-disable camelcase */
import React from 'react'
import { Map as L_Map } from 'leaflet'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {
    Button, Container, Header, Icon, List, Menu, Segment
} from 'semantic-ui-react'

import filters from '../../config/filters'
import INDICATORS_MAP_PROPS from '../../config/map'
import MapOverlaysControl from '../MapOverlaysControl'
import FilterCharts from '../FilterCharts'
import { Portal } from '../Map'

const indicatorsCategories = filters.reduce(
    (categoriesArray, category) => categoriesArray.concat({
        label: category.label,
        options: Object.entries(category.filters).reduce(
            (indicatorsArray, [indicator, { header: label }]) => indicatorsArray.concat({ label, indicator }),
            []
        )
    }),
    []
)

class Base extends React.Component {
    constructor(props) {
        super(props)

        Object.values(props.map.overlays).forEach(layer => layer.removeFrom(props.map))

        this.state = {
            active: 0,
            category: null,
            layers: null
        }

        this.features = null
        this.paLayer = null
        this.portal = new Portal({ id: '', position: 'topleft' })
        this.portal.addTo(props.map)
    }

    componentDidMount() {
        const {
            viewName, indicatorsDataUrl, layers, map
        } = this.props

        const xhrIndicators = new XMLHttpRequest()
        xhrIndicators.open('GET', indicatorsDataUrl)
        xhrIndicators.responseType = 'json'
        xhrIndicators.onload = () => {
            INDICATORS_MAP_PROPS.name = viewName
            INDICATORS_MAP_PROPS.layerData = xhrIndicators.response
            INDICATORS_MAP_PROPS.layerType = indicatorsDataUrl.indexOf('topojson') > -1

            map.updateIndicators(
                INDICATORS_MAP_PROPS,
                {
                    category: INDICATORS_MAP_PROPS.layerAttrs.defaultCategory,
                    updateBounds: true
                }
            )
            const { features } = xhrIndicators.response
            this.features = features
            this.setState({
                category: INDICATORS_MAP_PROPS.layerAttrs.defaultCategory
            })
        }
        xhrIndicators.send()

        let processedLayersCount = 0
        const processedLayers = []
        layers.forEach(
            (
                {
                    id,
                    label,
                    url,
                    type,
                    isActive,
                    legendElements,
                    events,
                    style
                },
                idx
            ) => {
                let legends
                if (legendElements) {
                    legends = [{
                        name: label,
                        elements: legendElements
                    }]
                }
                map.getLayer({
                    id: `${viewName}-${id}`,
                    url,
                    type,
                    legends
                }, (layer) => {
                    if (style) {
                        style(layer)
                    }
                    if (events) {
                        events(layer)
                    }
                    if (isActive) {
                        map.overlaysGroup.addLayer(layer)
                    }

                    processedLayers[idx] = {
                        id,
                        label,
                        isActive
                    }
                    processedLayersCount += 1
                    if (processedLayersCount === layers.length) {
                        this.setState({ layers: processedLayers })
                    }
                })
            }
        )
    }

    componentWillUnmount() {
        this.portal.remove()
        if (this.paLayer) {
            this.props.map.removeLayer(this.paLayer)
        }
    }

    filterUpdateCallback = (filteredFeatures) => {
        INDICATORS_MAP_PROPS.layerData = filteredFeatures
        const iconSelectNext = document.getElementById('iconSelect')
        const category = iconSelectNext ? iconSelectNext.value : this.state.category
        this.props.map.updateIndicators(INDICATORS_MAP_PROPS, { category })
    }

    toggleLayer = (layerId) => {
        const { map, viewName } = this.props

        if (this.state.layers) {
            this.setState((state) => {
                state.layers[layerId].isActive = !state.layers[layerId].isActive
                if (state.layers[layerId].isActive) {
                    map.overlays[`${viewName}-${state.layers[layerId].id}`].addTo(map)
                } else {
                    map.overlays[`${viewName}-${state.layers[layerId].id}`].removeFrom(map)
                }
                return state
            })
        }
    }

    render() {
        const menuItems = [
            { key: 'summary', name: 'Summary', icon: 'list alternate outline' },
            { key: 'indicators', name: 'Indicators', icon: 'dashboard' },
            { key: 'partners', name: 'Partners', icon: 'handshake outline' },
            { key: 'protected_areas', name: 'Conservation Management \n \& \n Areas', icon: 'leaf' }
        ]
        const {
            header, summaryContent, backUrl, map, viewName, indicatorsSummary
        } = this.props
        return (
            <Container>
                <Segment basic clearing padded>
                    <Header size="large" floated="left" inverted>
                        {header}
                    </Header>
                    {backUrl ?
                        (
                            <Link to={backUrl.url} style={{ float: 'right' }}>
                                <Button icon labelPosition="left">
                                    <Icon name="angle double left" />
                                    {backUrl.label}
                                </Button>
                            </Link>
                        ) :
                        null
                    }
                </Segment>
                <br />
                <br />
                <br />
                <Menu
                    widths={4}
                    icon="labeled"
                    items={menuItems}
                    activeIndex={this.state.active}
                    onItemClick={(e, data) => this.setState({ active: data.index })}
                />

                <Segment basic className={this.state.active === 0 ? '' : 'hidden'}>
                    <Header>
                        Summary
                    </Header>
                    {summaryContent}
                </Segment>

                <Segment basic className={this.state.active === 1 ? '' : 'hidden'}>
                    <div>
                        <p>
                            The indicators listed provide measures for the management effectiveness of Conservation Management Areas by country/territory under the Micronesia Challenge
                        </p>
                        { indicatorsSummary ?
                            (

                                    <a href={indicatorsSummary} target="_blank" rel="noopener noreferrer">
                                        <Button floated="right">Summary PDF</Button>
                                    </a>

                            ) : null
                        }
                    </div>
                    <Header>
                        Filter Indicator:
                    </Header>
                    {this.features ?
                        (
                            <FilterCharts
                                features={this.features}
                                filters={filters}
                                filterUpdateCallback={this.filterUpdateCallback}
                            />
                        ) :
                        null
                    }
                </Segment>

                <Segment basic className={this.state.active === 2 ? '' : 'hidden'}>
                    <Header>
                        Partners
                    </Header>
                    <List bulleted relaxed>
                        {this.props.partners.map(partner => (
                            <List.Item key={partner.label}>
                                {partner.url ?
                                    (
                                        <a
                                            href={partner.url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {partner.label}
                                        </a>
                                    ) :
                                    partner.label
                                }
                            </List.Item>
                        ))}

                    </List>
                </Segment>

                <Segment basic className={this.state.active === 3 ? '' : 'hidden'}>
                    <div className="container-space-between">
                        <Header>
                            Conservation Management Areas:
                        </Header>
                        <Button
                            className="cma-zoom-all-button"
                            onClick={() => map.fitBounds(map.indicatorsLayers[viewName].getBounds())}>
                                Zoom to all Conservation Management Areas
                        </Button>
                    </div>
                    <List bulleted>
                        {this.state.layers ?
                            map.overlays[`${viewName}-pa`].getLayers().map(layer => (
                                <List.Item
                                    key={layer.feature.properties.Name}
                                    as="a"
                                    onClick={() => {
                                        if (this.paLayer) {
                                            this.paLayer.options.color = '#077b50'
                                            map.removeLayer(this.paLayer)
                                        }
                                        this.paLayer = Object.assign(layer, Object.assign(layer.options, { color: 'red' }))
                                        map.addLayer(this.paLayer)
                                        map.fitBounds(layer.getBounds())
                                    }}
                                >
                                    {layer.feature.properties.Name}
                                </List.Item>
                            )) :
                            null
                        }
                    </List>
                </Segment>
                {this.state.layers ?
                    (
                        <MapOverlaysControl
                            container={this.portal.getContainer()}
                            defaultCategory={INDICATORS_MAP_PROPS.layerAttrs.defaultCategory}
                            categories={indicatorsCategories}
                            layers={this.state.layers}
                            toggleLayer={this.toggleLayer}
                        />
                    ) :
                    null
                }
            </Container>
        )
    }
}

Base.propTypes = {
    viewName: PropTypes.string.isRequired,
    map: PropTypes.instanceOf(L_Map).isRequired,
    backUrl: PropTypes.shape({
        url: PropTypes.string,
        label: PropTypes.string
    }),
    header: PropTypes.string.isRequired,
    summaryContent: PropTypes.node.isRequired,
    indicatorsSummary: PropTypes.string,
    indicatorsDataUrl: PropTypes.string.isRequired,
    partners: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        url: PropTypes.string
    })).isRequired,
    layers: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        url: PropTypes.string,
        type: PropTypes.string
    }))
}

Base.defaultProps = {
    backUrl: null,
    indicatorsSummary: null,
    layers: []
}

export default Base
