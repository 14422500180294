import PlotTypeIcon from '../images/plot-type.svg'
import DisturbanceIcon from '../images/disturbance.svg'
import ForestCoverIcon from '../images/forest-cover.jpg'
import ForestStructureIcon from '../images/forest-structure.svg'
import ForestTypeIcon from '../images/forest-type.png'
import InvasiveSpeciesIcon from '../images/invasive-species.png'
import SpeciesDiversityIcon from '../images/species-diversity.png'

// Qunatile breaks using version 2 (v02) of plots data
export const BREAKS = {
    TREE_BAPER: [0, 865, 6018, 10946, 17673, 73391],
    TREE_AVEDI: [0, 4, 6, 8, 10, 41],
    TREE_AVEHT: [0, 16, 28, 35, 42, 48, 99],
    SAP_BAPERA: [0, 485, 2007, 3692, 5905, 34417],
    SAP_AVEDIA: [0, 1, 2, 3, 5],
    SAP_AVEHT: [0, 6, 16, 19, 21, 24, 62],
    PERCINVS_1: [0, 10, 50, 80, 100],
    F_SapSpeci: [0, 2, 4, 6, 8, 10, 12],
    F_TreeSpec: [0, 4, 8, 12, 16, 20, 24, 28],
    F_TotalSpe: [0, 4, 8, 12, 16, 20, 24, 28],
    F_Treesper: [0, 20, 40, 60, 80, 100],
    F_Saplings: [0, 5, 12, 20, 35, 53],
    ForestCove: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
    HD_Total: [0, 1, 2, 3],
    ND_Total: [0, 1, 2, 3]
}

export default [
    {
        label: '',
        icon: PlotTypeIcon,
        filters: {
            PLOTTYPE: {
                dim: cf => cf.dimension(f => (f.properties.PLOTTYPE === 'FIA' ? 'FIA' : 'Micronesia Challenge')),
                group: v => v,
                filter: (currentValue, keySet) => keySet.has(currentValue),
                upperRange: dim => dim.groupAll().value(),
                header: 'Plot Type'
            }
        }
    },
    {
        label: '',
        icon: ForestTypeIcon,
        filters: {
            FOREST_COM: {
                dim: cf => cf.dimension(f => parseFloat(f.properties.FOREST_COM)),
                group: v => v,
                filter: (currentValue, keySet) => keySet.has(currentValue),
                label: (v) => {
                    if (v === 1) {
                        return 'Strand or halophytic vegetation'
                    }
                    if (v === 2) {
                        return 'Mangrove swamps'
                    }
                    if (v === 3) {
                        return 'Lowland tropical rainforest'
                    }
                    if (v === 4) {
                        return 'Montane Rainforest'
                    }
                    if (v === 8) {
                        return 'Agroforest'
                    }
                    return 'Other'
                },
                upperRange: dim => dim.groupAll().value(),
                header: 'Forest Type'
            }
        }
    },
    {
        label: '',
        icon: InvasiveSpeciesIcon,
        filters: {
            PERCINVS_1: {
                dim: cf => cf.dimension(f => parseFloat(f.properties.PERCINVS_1)),
                group: (v) => {
                    if (v === 0) {
                        return 0
                    }
                    for (let i = 1; i < BREAKS.PERCINVS_1.length; i++) {
                        if (v <= BREAKS.PERCINVS_1[i]) {
                            return i
                        }
                    }
                    return BREAKS.PERCINVS_1.length
                },
                filter: (currentValue, keySet) => {
                    const keySetIter = keySet.values()
                    while (true) {
                        const { value, done } = keySetIter.next()
                        if (value === 0 && currentValue === 0) {
                            return true
                        }
                        if (value !== undefined && currentValue > (BREAKS.PERCINVS_1[value - 1]) && currentValue <= (BREAKS.PERCINVS_1[value] || Infinity)) {
                            return true
                        }
                        if (done) {
                            return false
                        }
                    }
                },
                label: (v) => {
                    if (v === 0) {
                        return 'No invasives plant species'
                    }
                    if (v === 1) {
                        return '1-10% area covered by invasive plant species'
                    }
                    return `${BREAKS.PERCINVS_1[v - 1]}-${BREAKS.PERCINVS_1[v]}% area covered by invasive plant species`
                },
                upperRange: dim => dim.groupAll().value(),
                header: 'Invasive Plant Species'
            }
        }
    },
    {
        label: 'Disturbance',
        icon: DisturbanceIcon,
        filters: {
            HD_Total: {
                dim: cf => cf.dimension(f => (f.properties.HD_Total)),
                group: v => v,
                filter: (currentValue, keySet) => keySet.has(currentValue),
                label: v => {
                    if (v === 0) {
                        return 'No disturbance'
                    }
                    if (v === 1) {
                        return '1 type of human disturbance'
                    }
                    return `${v} types of human disturbance`
                },
                upperRange: dim => dim.groupAll().value(),
                header: 'Human Disturbance'
            },
            ND_Total: {
                dim: cf => cf.dimension(f => (f.properties.ND_Total)),
                group: v => v,
                filter: (currentValue, keySet) => keySet.has(currentValue),
                label: v => {
                    if (v === 0) {
                        return 'No disturbance'
                    }
                    if (v === 1) {
                        return '1 type of natural disturbance'
                    }
                    return `${v} types of natural disturbance`
                },
                upperRange: dim => dim.groupAll().value(),
                header: 'Natural Disturbance'
            }
        }
    },
    {
        label: 'Species Diversity',
        icon: SpeciesDiversityIcon,
        filters: {
            F_SapSpeci: {
                dim: cf => cf.dimension(f => parseFloat(f.properties.F_SapSpeci)),
                group: (v) => {
                    for (let i = 1; i < BREAKS.F_SapSpeci.length; i++) {
                        if (v < BREAKS.F_SapSpeci[i]) {
                            return i
                        }
                    }
                    return BREAKS.F_SapSpeci.length
                },
                filter: (currentValue, keySet) => {
                    const keySetIter = keySet.values()
                    while (true) {
                        const { value, done } = keySetIter.next()
                        if (value !== undefined && currentValue >= (BREAKS.F_SapSpeci[value - 1]) && currentValue < (BREAKS.F_SapSpeci[value] || Infinity)) {
                            return true
                        }
                        if (done) {
                            return false
                        }
                    }
                },
                label: (v) => {
                    if (v === BREAKS.F_SapSpeci.length) {
                        return `>= ${BREAKS.F_SapSpeci[v - 1]} sapling species per plot`
                    }
                    return `${BREAKS.F_SapSpeci[v - 1]}-${BREAKS.F_SapSpeci[v]} sapling species per plot`
                },
                upperRange: dim => dim.groupAll().value(),
                header: 'Number of Sapling Species Per Plot'
            },
            F_TreeSpec: {
                dim: cf => cf.dimension(f => parseFloat(f.properties.F_TreeSpec)),
                group: (v) => {
                    for (let i = 1; i < BREAKS.F_TreeSpec.length; i++) {
                        if (v < BREAKS.F_TreeSpec[i]) {
                            return i
                        }
                    }
                    return BREAKS.F_TreeSpec.length
                },
                filter: (currentValue, keySet) => {
                    const keySetIter = keySet.values()
                    while (true) {
                        const { value, done } = keySetIter.next()
                        if (value !== undefined && currentValue >= (BREAKS.F_TreeSpec[value - 1]) && currentValue < (BREAKS.F_TreeSpec[value] || Infinity)) {
                            return true
                        }
                        if (done) {
                            return false
                        }
                    }
                },
                label: (v) => {
                    if (v === BREAKS.F_TreeSpec.length) {
                        return `>= ${BREAKS.F_TreeSpec[v - 1]} saplings per plot`
                    }
                    return `${BREAKS.F_TreeSpec[v - 1]}-${BREAKS.F_TreeSpec[v]} tree species per plot`
                },
                upperRange: dim => dim.groupAll().value(),
                header: 'Number of Tree Species Per Plot'
            },
            F_TotalSpe: {
                dim: cf => cf.dimension(f => parseFloat(f.properties.F_TotalSpe)),
                group: (v) => {
                    for (let i = 1; i < BREAKS.F_TotalSpe.length; i++) {
                        if (v < BREAKS.F_TotalSpe[i]) {
                            return i
                        }
                    }
                    return BREAKS.F_TotalSpe.length
                },
                filter: (currentValue, keySet) => {
                    const keySetIter = keySet.values()
                    while (true) {
                        const { value, done } = keySetIter.next()
                        if (value !== undefined && currentValue >= (BREAKS.F_TotalSpe[value - 1]) && currentValue < (BREAKS.F_TotalSpe[value] || Infinity)) {
                            return true
                        }
                        if (done) {
                            return false
                        }
                    }
                },
                label: (v) => {
                    if (v === BREAKS.F_TotalSpe.length) {
                        return `>= ${BREAKS.F_TotalSpe[v - 1]} species`
                    }
                    return `${BREAKS.F_TotalSpe[v - 1]}-${BREAKS.F_TotalSpe[v]} species`
                },
                upperRange: dim => dim.groupAll().value(),
                header: 'Number of Total Species'
            }
        }
    },
    {
        label: 'Forest Structure',
        icon: ForestStructureIcon,
        filters: {
            F_Treesper: {
                dim: cf => cf.dimension(f => parseFloat(f.properties.F_Treesper)),
                group: (v) => {
                    if (v === 0) {
                        return 0
                    }
                    for (let i = 1; i < BREAKS.F_Treesper.length; i++) {
                        if (v <= BREAKS.F_Treesper[i]) {
                            return i
                        }
                    }
                    return BREAKS.F_Treesper.length
                },
                filter: (currentValue, keySet) => {
                    const keySetIter = keySet.values()
                    while (true) {
                        const { value, done } = keySetIter.next()
                        if (value === 0 && currentValue === 0) {
                            return true
                        }
                        if (value !== undefined && currentValue > (BREAKS.F_Treesper[value - 1]) && currentValue <= (BREAKS.F_Treesper[value] || Infinity)) {
                            return true
                        }
                        if (done) {
                            return false
                        }
                    }
                },
                label: (v) => {
                    if (v === 0) {
                        return 'No trees'
                    }
                    if (v === 1) {
                        return `1-${BREAKS.F_Treesper[v]} trees per plot`
                    }
                    return `${BREAKS.F_Treesper[v - 1] + 1}-${BREAKS.F_Treesper[v]} trees per plot`
                },
                upperRange: dim => dim.groupAll().value(),
                header: 'Number of Trees Per Plot'
            },
            F_Saplings: {
                dim: cf => cf.dimension(f => parseFloat(f.properties.F_Saplings)),
                group: (v) => {
                    if (v === 0) {
                        return 0
                    }
                    for (let i = 1; i < BREAKS.F_Saplings.length; i++) {
                        if (v <= BREAKS.F_Saplings[i]) {
                            return i
                        }
                    }
                    return BREAKS.F_Saplings.length
                },
                filter: (currentValue, keySet) => {
                    const keySetIter = keySet.values()
                    while (true) {
                        const { value, done } = keySetIter.next()
                        if (value === 0 && currentValue === 0) {
                            return true
                        }
                        if (value !== undefined && currentValue > (BREAKS.F_Saplings[value - 1]) && currentValue <= (BREAKS.F_Saplings[value] || Infinity)) {
                            return true
                        }
                        if (done) {
                            return false
                        }
                    }
                },
                label: (v) => {
                    if (v === 0) {
                        return 'No trees'
                    }
                    if (v === 1) {
                        return `1-${BREAKS.F_Saplings[v]} saplings per plot`
                    }
                    return `${BREAKS.F_Saplings[v - 1] + 1}-${BREAKS.F_Saplings[v]} saplings per plot`
                },
                upperRange: dim => dim.groupAll().value(),
                header: 'Number of Saplings Per Plot'
            },
            TREE_BAPER: {
                dim: cf => cf.dimension(f => parseFloat(f.properties.TREE_BAPER)),
                group: (v) => {
                    if (v === 0) {
                        return 0
                    }
                    for (let i = 1; i < BREAKS.TREE_BAPER.length; i++) {
                        if (v <= BREAKS.TREE_BAPER[i]) {
                            return i
                        }
                    }
                    return BREAKS.TREE_BAPER.length
                },
                filter: (currentValue, keySet) => {
                    const keySetIter = keySet.values()
                    while (true) {
                        const { value, done } = keySetIter.next()
                        if (value === 0 && currentValue === 0) {
                            return true
                        }
                        if (value !== undefined && currentValue > (BREAKS.TREE_BAPER[value - 1]) && currentValue <= (BREAKS.TREE_BAPER[value] || Infinity)) {
                            return true
                        }
                        if (done) {
                            return false
                        }
                    }
                },
                label: (v) => {
                    if (v === 0) {
                        return 'No trees'
                    }
                    if (v === 1) {
                        return `1-${BREAKS.TREE_BAPER[v]} sq ft`
                    }
                    return `${BREAKS.TREE_BAPER[v - 1] + 1}-${BREAKS.TREE_BAPER[v]} sq ft`
                },
                upperRange: dim => dim.groupAll().value(),
                header: 'Tree Basal Area Per Acre'
            },
            SAP_BAPERA: {
                dim: cf => cf.dimension(f => parseFloat(f.properties.SAP_BAPERA)),
                group: (v) => {
                    if (v === 0) {
                        return 0
                    }
                    for (let i = 1; i < BREAKS.SAP_BAPERA.length; i++) {
                        if (v <= BREAKS.SAP_BAPERA[i]) {
                            return i
                        }
                    }
                    return BREAKS.SAP_BAPERA.length
                },
                filter: (currentValue, keySet) => {
                    const keySetIter = keySet.values()
                    while (true) {
                        const { value, done } = keySetIter.next()
                        if (value === 0 && currentValue === 0) {
                            return true
                        }
                        if (value !== undefined && currentValue > (BREAKS.SAP_BAPERA[value - 1]) && currentValue <= (BREAKS.SAP_BAPERA[value] || Infinity)) {
                            return true
                        }
                        if (done) {
                            return false
                        }
                    }
                },
                label: (v) => {
                    if (v === 0) {
                        return 'No trees'
                    }
                    if (v === 1) {
                        return `1-${BREAKS.SAP_BAPERA[v]} sq ft`
                    }
                    return `${BREAKS.SAP_BAPERA[v - 1] + 1}-${BREAKS.SAP_BAPERA[v]} sq ft`
                },
                upperRange: dim => dim.groupAll().value(),
                header: 'Sapling Basal Area Per Acre'
            },
            TREE_AVEDI: {
                dim: cf => cf.dimension(f => parseFloat(f.properties.TREE_AVEDI)),
                group: (v) => {
                    if (v === 0) {
                        return 0
                    }
                    for (let i = 1; i < BREAKS.TREE_AVEDI.length; i++) {
                        if (v <= BREAKS.TREE_AVEDI[i]) {
                            return i
                        }
                    }
                    return BREAKS.TREE_AVEDI.length
                },
                filter: (currentValue, keySet) => {
                    const keySetIter = keySet.values()
                    while (true) {
                        const { value, done } = keySetIter.next()
                        if (value === 0 && currentValue === 0) {
                            return true
                        }
                        if (value !== undefined && currentValue > (BREAKS.TREE_AVEDI[value - 1]) && currentValue <= (BREAKS.TREE_AVEDI[value] || Infinity)) {
                            return true
                        }
                        if (done) {
                            return false
                        }
                    }
                },
                label: (v) => {
                    if (v === 0) {
                        return 'Not applicable (no trees)'
                    }
                    if (v === 1) {
                        return `1-${BREAKS.TREE_AVEDI[v]} inches`
                    }
                    return `${BREAKS.TREE_AVEDI[v - 1] + 1}-${BREAKS.TREE_AVEDI[v]} inches`
                },
                upperRange: dim => dim.groupAll().value(),
                header: 'Tree Average Diameter'
            },
            SAP_AVEDIA: {
                dim: cf => cf.dimension(f => parseFloat(f.properties.SAP_AVEDIA)),
                group: (v) => {
                    if (v === 0) {
                        return 0
                    }
                    for (let i = 1; i < BREAKS.SAP_AVEDIA.length; i++) {
                        if (v <= BREAKS.SAP_AVEDIA[i]) {
                            return i
                        }
                    }
                    return BREAKS.SAP_AVEDIA.length
                },
                filter: (currentValue, keySet) => {
                    const keySetIter = keySet.values()
                    while (true) {
                        const { value, done } = keySetIter.next()
                        if (value === 0 && currentValue === 0) {
                            return true
                        }
                        if (value !== undefined && currentValue > (BREAKS.SAP_AVEDIA[value - 1]) && currentValue <= (BREAKS.SAP_AVEDIA[value] || Infinity)) {
                            return true
                        }
                        if (done) {
                            return false
                        }
                    }
                },
                label: (v) => {
                    if (v === 0) {
                        return 'Not applicable (no saplings)'
                    }
                    if (v === 1) {
                        return `${BREAKS.SAP_AVEDIA[v]} inch`
                    }
                    if (v === 2 || v === 3) {
                        return `${BREAKS.SAP_AVEDIA[v]} inches`
                    }
                    return `${BREAKS.SAP_AVEDIA[v - 1] + 1}-${BREAKS.SAP_AVEDIA[v]} inches`
                },
                upperRange: dim => dim.groupAll().value(),
                header: 'Sapling Average Diameter'
            },
            TREE_AVEHT: {
                dim: cf => cf.dimension(f => parseFloat(f.properties.TREE_AVEHT)),
                group: (v) => {
                    if (v === 0) {
                        return 0
                    }
                    for (let i = 1; i < BREAKS.TREE_AVEHT.length; i++) {
                        if (v <= BREAKS.TREE_AVEHT[i]) {
                            return i
                        }
                    }
                    return BREAKS.TREE_AVEHT.length
                },
                filter: (currentValue, keySet) => {
                    const keySetIter = keySet.values()
                    while (true) {
                        const { value, done } = keySetIter.next()
                        if (value === 0 && currentValue === 0) {
                            return true
                        }
                        if (value !== undefined && currentValue > (BREAKS.TREE_AVEHT[value - 1]) && currentValue <= (BREAKS.TREE_AVEHT[value] || Infinity)) {
                            return true
                        }
                        if (done) {
                            return false
                        }
                    }
                },
                label: (v) => {
                    if (v === 0) {
                        return 'Not applicable (no trees)'
                    }
                    if (v === 1) {
                        return `1-${BREAKS.TREE_AVEHT[v]} feet`
                    }
                    return `${BREAKS.TREE_AVEHT[v - 1] + 1}-${BREAKS.TREE_AVEHT[v]} feet`
                },
                upperRange: dim => dim.groupAll().value(),
                header: 'Trees Average Height'
            },
            SAP_AVEHT: {
                dim: cf => cf.dimension(f => parseFloat(f.properties.SAP_AVEHT)),
                group: (v) => {
                    if (v === 0) {
                        return 0
                    }
                    for (let i = 1; i < BREAKS.SAP_AVEHT.length; i++) {
                        if (v <= BREAKS.SAP_AVEHT[i]) {
                            return i
                        }
                    }
                    return BREAKS.SAP_AVEHT.length
                },
                filter: (currentValue, keySet) => {
                    const keySetIter = keySet.values()
                    while (true) {
                        const { value, done } = keySetIter.next()
                        if (value === 0 && currentValue === 0) {
                            return true
                        }
                        if (value !== undefined && currentValue > (BREAKS.SAP_AVEHT[value - 1]) && currentValue <= (BREAKS.SAP_AVEHT[value] || Infinity)) {
                            return true
                        }
                        if (done) {
                            return false
                        }
                    }
                },
                label: (v) => {
                    if (v === 0) {
                        return 'Not applicable (no saplings)'
                    }
                    if (v === 1) {
                        return `1-${BREAKS.SAP_AVEHT[v]} feet`
                    }
                    return `${BREAKS.SAP_AVEHT[v - 1] + 1}-${BREAKS.SAP_AVEHT[v]} feet`
                },
                upperRange: dim => dim.groupAll().value(),
                header: 'Saplings Average Height'
            }
        }
    },
    {
        icon: ForestCoverIcon,
        filters: {
            ForestCove: {
                dim: cf => cf.dimension(f => parseFloat(f.properties.ForestCove)),
                group: v => Math.floor(v / 10),
                filter: (currentValue, keySet) => {
                    const keySetIter = keySet.values()
                    while (true) {
                        const { value, done } = keySetIter.next()
                        if (value !== undefined && currentValue >= value * 10 && currentValue < (value + 1) * 10) {
                            return true
                        }
                        if (done) {
                            return false
                        }
                    }
                },
                label: v => `${v * 10} - ${(v + 1) * 10}% forest cover`,
                upperRange: dim => dim.groupAll().value(),
                header: 'Forest Cover'
            }
        }
    }
]
