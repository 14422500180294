import React from 'react'
import { Image, Menu } from 'semantic-ui-react'

import logo from '../images/logo_cbi_sm.svg'
import MCT from '../images/Logos/MCTlogo.jpg'
import College from '../images/Logos/CollegeOfMicronesia.jpg'
import UniOfGuam from '../images/Logos/UniOfGuam.png'
import USFS from '../images/Logos/USFS.jpg'
import NC from '../images/Logos/NatureConservancy.jpg'

export default () => (
    <Menu borderless>
        <Menu.Item target="_blank" rel="noopener noreferrer" href="http://www.ourmicronesia.org/">
            The Micronesia Conservation Trust
            <Image id="LogoCBI" src={MCT} size="mini" />
        </Menu.Item>
        <Menu.Item target="_blank" rel="noopener noreferrer" href="https://www.fs.usda.gov/">
            USFS
            <Image id="LogoCBI" src={USFS} size="mini" />
        </Menu.Item>
        <Menu.Item target="_blank" rel="noopener noreferrer" href="https://www.uog.edu/">
            University of Guam
            <Image id="LogoCBI" src={UniOfGuam} size="mini" />
        </Menu.Item>
        <Menu.Item target="_blank" rel="noopener noreferrer" href="https://www.comfsm.fm/">
            College of Micronesia
            <Image id="LogoCBI" src={College} size="mini" />
        </Menu.Item>
        <Menu.Item target="_blank" rel="noopener noreferrer" href="https://www.nature.org/">
            The Nature Conservancy
            <Image id="LogoCBI" src={NC} size="mini" />
        </Menu.Item>
        <Menu.Item position="right" target="_blank" rel="noopener noreferrer" href="https://consbio.org/">
            Conservation Biology Institute
            <Image id="LogoCBI" src={logo} size="mini" />
        </Menu.Item>
    </Menu>
)
