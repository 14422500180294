/* eslint-disable no-underscore-dangle */
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'

import { topojson } from 'leaflet-omnivore'
import 'leaflet-basemaps/L.Control.Basemaps'
import 'leaflet-basemaps/L.Control.Basemaps.css'

import 'leaflet-html-legend'
import 'leaflet-html-legend/src/L.Control.HtmlLegend.css'

import filters from '../config/filters'

const categories = filters.reduce(
    (categoriesArray, category) => categoriesArray.concat({
        label: category.label,
        options: Object.entries(category.filters).reduce(
            (indicatorsArray, [indicator, { header: label }]) => indicatorsArray.concat({ label, indicator }),
            []
        )
    }),
    []
)


const BASEMAPS = [
    L.tileLayer('//{s}.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}', {
        attribution: `
            Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ, TomTom, Intermap, iPC, USGS, FAO, NPS, NRCAN, GeoBase,
            Kadaster NL, Ordnance Survey, Esri Japan, METI, Esri China (Hong Kong), and the GIS User Community`,
        subdomains: ['server', 'services'],
        label: 'ESRI Topo'
    }),
    L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
        attribution: `
            Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP,
            UPR-EGP, and the GIS User Community`,
        label: 'ESRI Imagery'
    })
]

const OVERLAYS_ATTRS = {
    pa: {
        id: 'pa',
        label: 'Conservation Management Areas',
        isActive: true,
        style: layer => layer.setStyle({
            color: '#077b50',
            weight: 2,
            dashArray: 0,
            opacity: 0.5,
            fillOpacity: 0.2
        }),
        events: layer => layer.on('click', l => l.layer.bindPopup(l.layer.feature.properties.Name).openPopup())
    },
    veg: area => ({
        id: 'mc_vegetation',
        label: 'Land Cover',
        isActive: false,
        type: 'service',
        url: `https://mbtileserver.databasin.org/services/usfs-micronesia/veg-${area}/tiles/{z}/{x}/{y}.png`,
        legendElements: [
            ['Bare/Developed/Impervious', '#4f4f4f'],
            ['Forest', '#37a801'],
            ['Grassland/Crops/Open Space/Shrub', '#ffecbe'],
            ['Marsh/Water', '#0085a8'],
            ['Mangrove', '#ce669a']
        ].map(([label, color]) => ({
            label,
            html: '',
            style: {
                'background-color': color,
                width: '10px',
                height: '10px'
            }
        }))
    })
}

export const Portal = L.Control.extend({
    onAdd() {
        const portal = L.DomUtil.create('div', 'portal')
        portal.id = this.options.id
        L.DomEvent.disableClickPropagation(portal)
        return portal
    }
})

const initBaseControls = (map) => {
    L.control.basemaps({
        basemaps: BASEMAPS,
        tileX: 0,
        tileY: 0,
        tileZ: 1,
        position: 'bottomleft'
    }).addTo(map)

    L.control.zoom({
        position: 'topright'
    }).addTo(map)

    map.overlayLegendControl = L.control.htmllegend({
        position: 'bottomright',
        collapseSimple: true,
        detectStretched: true,
        disableVisibilityControls: true
    })
    map.overlayLegendControl.addTo(map)
}

const initLayers = (map) => {
    map.overlays = {}
    map.overlaysGroup = L.layerGroup()
    map.overlaysGroup.addTo(map)

    map.indicatorsLayers = {}
    map.indicatorsLayerGroup = L.layerGroup()
    map.indicatorsLayerGroup.addTo(map)
    map.indicatorsLegendControl = L.control.htmllegend({
        position: 'bottomright',
        collapseSimple: true,
        detectStretched: true,
        disableVisibilityControls: true
    })
    map.indicatorsLegendControl.addTo(map)
}

const initEvents = (map) => {
    map.on('zoomend', () => {
        const mapContainer = map.getContainer()
        const mapClasses = L.DomUtil.getClass(mapContainer)
        mapClasses
            .split(' ')
            .filter(c => c.indexOf('zoom-') === 0)
            .forEach(c => L.DomUtil.removeClass(mapContainer, c))
        L.DomUtil.addClass(mapContainer, `zoom-${map.getZoom()}`)
    })
}

const loadLayer = (url, callback) => {
    const xhr = new XMLHttpRequest()
    xhr.open('GET', url)
    xhr.responseType = 'json'
    xhr.onload = () => {
        callback(xhr.response)
    }
    xhr.send()
}

function addLegend(map, layer, legends) {
    if (legends) {
        legends[0].layer = layer
        L.control.htmllegend({
            legends,
            position: 'bottomright',
            collapseSimple: true,
            detectStretched: true,
            disableVisibilityControls: true
        }).addTo(map)
    }
}

function getLayer(
    {
        id, url, type, legends
    },
    callback
) {
    if (this.overlays[id]) {
        callback(this.overlays[id])
    } else {
        switch (type) {
            case 'service':
                this.overlays[id] = L.tileLayer(url)
                addLegend(this, this.overlays[id], legends)
                callback(this.overlays[id])
                break
            case 'geojson':
                loadLayer(url, (layer) => {
                    this.overlays[id] = L.geoJSON(layer)
                    addLegend(this, this.overlays[id], legends)
                    callback(this.overlays[id])
                })
                break
            case 'topojson':
                loadLayer(url, (layer) => {
                    this.overlays[id] = topojson.parse(layer)
                    addLegend(this, this.overlays[id], legends)
                    callback(this.overlays[id])
                })
                break
            default:
                throw Error(`Unsupported layer type: ${type}`)
        }
    }
}

function updateIndicators(
    mapProps,
    {
        updateBounds,
        category
    }
) {
    // `this` is an instance of Leaflet Map
    const {
        name,
        layerData,
        layerType,
        layerAttrs
    } = mapProps
    const {
        style,
        options,
        legend,
        defaultCategory,
        categoryLegend,
        events
    } = layerAttrs

    let layerGroup
    if (this.indicatorsLayers[name]) {
        layerGroup = this.indicatorsLayers[name]
        layerGroup.clearLayers()
    } else {
        layerGroup = L.featureGroup()
        if (events) {
            events.forEach(e => layerGroup.on(e.event, e.handler))
        }
        this.indicatorsLayers[name] = layerGroup
    }

    let layer
    if (layerType === 'topo') {
        layer = topojson.parse(layerData)
    } else {
        layer = L.geoJSON(layerData, options)
    }

    if (style) {
        layer.getLayers().forEach(l => style(l, category || defaultCategory))
    }
    layerGroup.addLayer(layer)

    Object.keys(this.indicatorsLegendControl._entries).forEach(idx => this.indicatorsLegendControl.removeLegend(idx))
    if (legend) {
        legend.layer = layerGroup
        this.indicatorsLegendControl.addLegend(legend)
    }
    if (categoryLegend && categoryLegend[category]) {
        categoryLegend[category].layer = layerGroup
        const htmlOptions = []
        const createNestedOptions = nestedOptions => nestedOptions.map(option => `<option value="${option.indicator}">${option.label}</option>`)
        categories.forEach(
            ({ label, options: categoryOptions }) => (
                label ?
                    htmlOptions.push(`<optgroup label="${label}">${createNestedOptions(categoryOptions).join()}</optgroup>`) :
                    categoryOptions.forEach(option => htmlOptions.push(`<option value="${option.indicator}">${option.label}</option>`))
            )
        )
        const newCategoryLegend = categoryLegend[category]
        newCategoryLegend.name = ''
        // conditional corrects redundant html select element bug
        if (newCategoryLegend.elements[0].label) {
            newCategoryLegend.elements.unshift(
                { html: `<div><p class="legend-dropdown">Select Icon Type on Map</p><select id="iconSelect">${htmlOptions.join()}</select><br/<br/><br/></div>` }
            )
        }
        this.indicatorsLegendControl.addLegend(newCategoryLegend)
        const iconSelectNext = document.getElementById('iconSelect')
        if (iconSelectNext) {
            iconSelectNext.value = category || defaultCategory
            iconSelectNext.addEventListener(
                'change',
                e => this.updateIndicators(mapProps, { category: e.target.value })
            )
        }
    }

    this.indicatorsLayerGroup.clearLayers()
    this.indicatorsLayerGroup.addLayer(layerGroup)
    if (updateBounds) {
        this.fitBounds(layerGroup.getBounds())
    }
}

const initMap = (store) => {
    const map = L.map('MapContainer', {
        layers: [BASEMAPS[0]],
        maxZoom: 16,
        zoomControl: false,
        preferCanvas: true
    })

    map.store = store

    initBaseControls(map)
    initLayers(map)
    initEvents(map)

    map.updateIndicators = updateIndicators.bind(map)
    map.getLayer = getLayer.bind(map)

    return map
}

export { initMap, OVERLAYS_ATTRS }
