import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import ReactRouterPropTypes from 'react-router-prop-types'
import { Image, Menu } from 'semantic-ui-react'

import logo from '../images/logo_mc.png'

const PageHeader = props => (
    <React.Fragment>
        <Menu id="HeaderBanner" borderless>
            <Menu.Item>
                <Link to="/intro">
                    <Image src={logo} size="small" />
                </Link>
            </Menu.Item>
        </Menu>
        <Menu id="HeaderMenu" widths={5} color="blue" inverted>
            <Menu.Item active={props.location.pathname === '/palau'}>
                <Link to="/palau">
                    Republic of Palau
                </Link>
            </Menu.Item>
            <Menu.Item active={props.location.pathname === '/guam'}>
                <Link to="/guam">
                    Guam
                </Link>
            </Menu.Item>
            <Menu.Item active={props.location.pathname === '/mariana'}>
                <Link to="/mariana">
                    Commonwealth of Northern Mariana Islands
                </Link>
            </Menu.Item>
            <Menu.Item active={props.location.pathname === '/fsm'}>
                <Link to="/fsm">
                    Federated States of Micronesia
                </Link>
            </Menu.Item>
            <Menu.Item active={props.location.pathname === '/marshall'}>
                <Link to="/marshall">
                    Republic of Marshall Islands
                </Link>
            </Menu.Item>
        </Menu>
    </React.Fragment>
)

PageHeader.propTypes = {
    location: ReactRouterPropTypes.location.isRequired
}

export default withRouter(PageHeader)
