import React from 'react'
import { scaleLinear, select } from 'd3'

import { VerticalBarChartPropTypes } from '../../propTypes'

class VerticalBarChart extends React.Component {
    constructor(props) {
        super(props)

        this.getBars = this.getBars.bind(this)
    }

    componentDidMount() {
        this.getBars()
    }

    componentDidUpdate() {
        this.getBars()
    }

    getBars = () => {
        const {
            data,
            filters,
            fallbackColor,
            fallbackFilteredColor,
            ignoreValueLabel,
            showBasebar,
            margin,
            barHeight,
            gap,
            labelPosDistance,
            width,
            range,
            events
        } = this.props

        const x = scaleLinear()
            .range([0, width - 30])
            .domain(range)

        const svg = select(this.svg)
        svg.html(null)
        const g = svg
            .append('g')
            .attr('transform', `translate(${2 * margin},0)`)

        data.forEach((d, idx) => {
            const { value, valueLabel } = d

            const barX = x(0)
            const barY = ((idx + 1) * (barHeight + gap)) + barHeight

            const baseBarWidth = x(range[1])
            const barWidth = x(value)

            let barColor
            let textColor
            if (filters.has(d.key)) {
                barColor = d.filteredColor || fallbackFilteredColor
                textColor = d.filteredColor || fallbackFilteredColor
            } else {
                barColor = d.color || fallbackColor
                textColor = '#888'
            }

            const dg = g
                .append('g')
                .attr('class', events.length ? 'interactive' : '')

            dg.append('text')
                .attr('x', barX + 5)
                .attr('y', barY - labelPosDistance)
                .attr('fill', textColor)
                .text(d.keyLabel)

            if (!ignoreValueLabel) {
                const valueLabelOffset = showBasebar ? baseBarWidth : barWidth
                dg.append('text')
                    .attr('text-anchor', 'end')
                    .attr('x', barX + valueLabelOffset)
                    .attr('y', barY - labelPosDistance)
                    .attr('fill', textColor)
                    .text(valueLabel || value)
            }

            dg.append('rect')
                .attr('x', barX)
                .attr('y', barY)
                .attr('rx', 2)
                .attr('ry', 2)
                .attr('width', baseBarWidth)
                .attr('height', barHeight)
                .attr('fill', '#eee')
                .attr('stroke', '#eee')

            dg.append('rect')
                .attr('x', barX)
                .attr('y', barY)
                .attr('rx', 2)
                .attr('ry', 2)
                .attr('width', barWidth)
                .attr('height', barHeight)
                .attr('fill', barColor)

            const chartHeight = (idx + 2) * (barHeight + gap)
            svg.attr('height', chartHeight)

            events.forEach((e) => {
                dg.on(
                    e.event,
                    () => e.handler({
                        type: e.event,
                        target: dg,
                        key: d.key,
                        value
                    })
                )
            })
        })
    }

    render() {
        return (
            <svg
                ref={(c) => { this.svg = c }}
                className={this.props.filters.size ? 'hasFilter' : ''}
                width={this.props.width - 2 * this.props.margin}
            />
        )
    }
}

VerticalBarChart.propTypes = VerticalBarChartPropTypes

VerticalBarChart.defaultProps = {
    filters: new Set(),
    fallbackColor: '#0892d0',
    fallbackFilteredColor: '#ee7d14',
    showBasebar: true,
    ignoreValueLabel: false,
    margin: 5,
    width: 400,
    barHeight: 4,
    gap: 30,
    labelPosDistance: 5,
    events: []
}

export default VerticalBarChart
