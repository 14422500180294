import { Icon, Modal } from 'semantic-ui-react'
import React from 'react'
import PropTypes from 'prop-types'
import FILTERS_HELP from '../../config/filtersHelp'

const HelpModal = ({ label }) => (
    <Modal
        closeIcon
        size="small"
        trigger={(
            <Icon
                name="info circle blue"
                size="large"
                style={{
                    margin: '2px 0 0 5px',
                    cursor: 'pointer'
                }}
            />
        )}
    >
        <Modal.Header>{label}</Modal.Header>
        <Modal.Content>
            <Modal.Description>{FILTERS_HELP[label]}</Modal.Description>
        </Modal.Content>
    </Modal>
)

HelpModal.propTypes = {
    label: PropTypes.oneOf([Object.keys(FILTERS_HELP)]).isRequired
}

export default HelpModal
