import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import {
    Checkbox, List, Segment
} from 'semantic-ui-react'

class MapOverlaysControl extends React.Component {
    constructor(props) {
        super(props)

        const categoriesVisibility = {}
        props.categories.forEach(({ label }, idx) => {
            if (label) {
                categoriesVisibility[idx] = false
            }
        })
    }

    render() {
        const {
            container, layers, toggleLayer
        } = this.props

        return (
            ReactDOM.createPortal(
                (
                    <Segment basic>
                        <List>
                            {layers.map(({ id, label, isActive }, idx) => (
                                <List.Item key={id}>
                                    <Checkbox
                                        label={label}
                                        checked={isActive}
                                        onChange={() => toggleLayer(idx)}
                                    />
                                </List.Item>
                            ))}
                        </List>
                    </Segment>
                ),
                container
            )
        )
    }
}

MapOverlaysControl.propTypes = {
    container: PropTypes.instanceOf(Element).isRequired,
    categories: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            options: PropTypes.arrayOf(
                PropTypes.shape({
                    indicator: PropTypes.string,
                    label: PropTypes.string
                })
            )
        })
    ).isRequired,
    layers: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        isActive: PropTypes.bool
    })).isRequired,
    toggleLayer: PropTypes.func.isRequired
}

export default MapOverlaysControl
