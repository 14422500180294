import { fromJS } from 'immutable'

import { ACTIONS } from '../actions/data'

const DATA_STATE = {}

const data = (state, action) => {
    switch (action.type) {
        case ACTIONS.UPDATE_DATA:
            return fromJS(action.data)
        default:
            return state
    }
}

export { data, DATA_STATE }
