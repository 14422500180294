/* eslint-disable camelcase */
import React from 'react'
import { Map as L_Map } from 'leaflet'
import PropTypes from 'prop-types'
import { Segment } from 'semantic-ui-react'

import Indicators from '../../../files/fsm_kosrae.geojson'
import PAs from '../../../files/fsm_kosrae_pa.geojson'
import Profile from '../../../images/kosrae.jpg'
import IndicatorsSummary from '../../../files/MCSummary_Kosrae.pdf'

import Base from '../Base'
import { OVERLAYS_ATTRS } from '../../Map'

const Props = {
    viewName: 'kosrae',
    header: 'Kosrae',
    backUrl: {
        url: '/fsm',
        label: 'Back to FSM overview'
    },
    profileUrl: Profile,
    partners: [
        { label: 'Kosrae Islands Resource Management Authority (KIRMA)', url: '' },
        { label: 'Kosrae Conservation and Safety Organization', url: '' },
        { label: 'Yela Environmental Landowners Authority (YELA)', url: '' }
    ],
    summaryContent: (
        <Segment basic>
            <p>
                Prior to the forest inventory in Kosrae, representatives from the state and local organizations worked together to identify areas to be included in this regional monitoring program.
            </p>
            <p>
                In 2016 there are 26 FIA plots inventoried in Kosrae.
            </p>
            <ul>
                <li>
                    12 are FIA grid plots
                </li>
                <li>
                    14 are Micronesia Challenge plots. These are on a slightly more intensified grid system and primarily fall within conservation areas, forest stewardship areas, and a proposed area for monitoring by the local community.
                </li>
            </ul>
            <br />
            <p>
                More information on Kosrae’s monitoring programs are available through these websites:
            </p>
            <ul className="summary-links">
                <li>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.fs.fed.us/pnw/rma/fia-topics/inventory-data/index.php"
                    >
                        The complete Forest Inventory and Analysis database for the Federated States of Micronesia
                    </a>
                </li>
                <br />
                <li>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="http://www.comfsm.fm/bchm/TerrestrialBio.pdf"
                    >
                        Terrestrial Biodiversity of the FSM
                    </a>
                </li>
                <br />
                <li>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="http://www.reefresilience.org/case-studies/micronesia-mpa-design/"
                    >
                        Designing a Protected Area Network in FSM
                    </a>
                </li>
            </ul>
        </Segment>
    ),
    indicatorsDataUrl: Indicators,
    indicatorsSummary: IndicatorsSummary,
    layers: [
        Object.assign({
            url: PAs,
            type: 'geojson'
        }, OVERLAYS_ATTRS.pa),
        OVERLAYS_ATTRS.veg('kosrae')
    ]
}

const Kosrae = props => (
    <div className="darken-kosrae"><Base {...Props} map={props.map} /></div>
)

Kosrae.propTypes = {
    // props
    map: PropTypes.instanceOf(L_Map).isRequired
}

export default Kosrae
