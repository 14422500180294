/* eslint-disable camelcase */
import React from 'react'
import { Map as L_Map } from 'leaflet'
import PropTypes from 'prop-types'
import { Segment } from 'semantic-ui-react'

import Indicators from '../../../files/fsm_yap.geojson'
import PAs from '../../../files/fsm_yap_pa.topojson'
import Profile from '../../../images/yap.jpg'
import IndicatorsSummary from '../../../files/MCSummary_Yap.pdf'

import Base from '../Base'
import { OVERLAYS_ATTRS } from '../../Map'

const Props = {
    viewName: 'yap',
    header: 'Yap',
    backUrl: {
        url: '/fsm',
        label: 'Back to FSM overview'
    },
    profileUrl: Profile,
    partners: [
        { label: 'Yap Community Action Program', url: '' },
        { label: 'Yap State Forestry', url: '' }
    ],
    summaryContent: (
        <Segment basic>
            <p>
                Prior to data collection in Yap, a working group of state and local organizations identified priority areas for adding additional Micronesia Challenge monitoring plots. The group selected priority watersheds, a few key forests of biological significance, and a forest stewardship area on the main island of Yap. The group also wanted the outer islands to be included in the intensified monitoring program. These additional plots were selected by placing a systematic grid over selected outer islands; if a plot fell on land then it was sampled.
            </p>
            <p>
                In 2016 Yap inventoried 52 FIA plots:
            </p>
            <ul>
                <li>
                    14 FIA grid plots
                </li>
                <li>
                    38 Micronesia Challenge plots
                </li>
            </ul>
            <br />
            <p>
                More information on Yap’s monitoring programs are available through these websites:
            </p>
            <ul className="summary-links">
                <li>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.fs.fed.us/pnw/rma/fia-topics/inventory-data/index.php"
                    >
                        The complete Forest Inventory and Analysis database for the Federated States of Micronesia
                    </a>
                </li>
                <br />
                <li>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="http://www.comfsm.fm/bchm/TerrestrialBio.pdf"
                    >
                        Terrestrial Biodiversity of the FSM
                    </a>
                </li>
                <br />
                <li>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="http://www.reefresilience.org/case-studies/micronesia-mpa-design/"
                    >
                        Designing a Protected Area Network in FSM
                    </a>
                </li>
            </ul>
        </Segment>
    ),
    indicatorsDataUrl: Indicators,
    indicatorsSummary: IndicatorsSummary,
    layers: [
        Object.assign({
            url: PAs,
            type: 'topojson'
        }, OVERLAYS_ATTRS.pa),
        OVERLAYS_ATTRS.veg('yap')
    ]
}

const Yap = props => (
    <div className="darken-yap"><Base {...Props} map={props.map} /></div>
)

Yap.propTypes = {
    // props
    map: PropTypes.instanceOf(L_Map).isRequired
}

export default Yap
