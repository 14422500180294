/* eslint-disable react/no-unused-state */
import React from 'react'
import crossfilter from 'crossfilter2'
import {
    Header, Icon, Segment, Divider, Image
} from 'semantic-ui-react'

import { FilterChartsPropTypes } from '../../propTypes'
import FilterChart from './FilterChart'
import FILTERS_HELP from '../../config/filtersHelp'
import HelpModal from './HelpModal'

class FilterCharts extends React.Component {
    constructor(props) {
        super(props)

        const categoriesVisibility = {}
        props.filters.forEach(({ label }, idx) => {
            if (label) {
                categoriesVisibility[idx] = idx === 0
            }
        })
        this.state = {
            lastFilterUpdate: new Date(),
            categoriesVisibility
        }

        this.cf = crossfilter(props.features)
        this.dimensions = {}
        this.filters = {}
    }

    handleFilterUpdate = ({ key }, categoryIdx, filterKey, dim) => {
        const { filters, filterUpdateCallback } = this.props
        const filterSet = this.filters[filterKey] || new Set()
        if (filterSet.has(key)) {
            filterSet.delete(key)
        } else {
            filterSet.add(key)
        }
        if (filterSet.size) {
            dim.filterFunction(v => filters[categoryIdx].filters[filterKey].filter(v, filterSet))
        } else {
            dim.dispose()
        }
        this.filters[filterKey] = filterSet
        if (filterUpdateCallback) {
            filterUpdateCallback(this.cf.allFiltered())
        }
        this.setState({ lastFilterUpdate: new Date() })
    }

    removeFilters = (filterKey) => {
        const { filterUpdateCallback } = this.props
        const filterSet = this.filters[filterKey] || new Set()
        filterSet.clear()
        this.dimensions[filterKey].dispose()
        if (filterUpdateCallback) {
            filterUpdateCallback(this.cf.allFiltered())
        }
        this.setState({ lastFilterUpdate: new Date() })
    }

    toggleVisibility = (idx) => {
        this.setState((state) => {
            state.categoriesVisibility[idx] = !state.categoriesVisibility[idx]
            return state
        })
    }

    renderFilterChart(categoryIdx, key, filter, idx, icon = '') {
        if (!this.dimensions[key]) {
            this.dimensions[key] = filter.dim(this.cf)
        }
        const dim = this.dimensions[key]
        let upperRange
        if (filter.upperRange) {
            if (typeof filter.upperRange === 'function') {
                upperRange = filter.upperRange(dim)
            } else {
                ({ upperRange } = filter)
            }
        }
        const data = dim.group(filter.group).all().map((d) => {
            const value = filter.value ? filter.value(d.value) : d.value
            if (!filter.upperRange && value > upperRange) {
                upperRange = value
            }
            return {
                key: d.key,
                keyLabel: filter.label ? filter.label(d.key) : d.key,
                value,
                valueLabel: filter.valueLabel ? filter.valueLabel(value) : null
            }
        })
        const props = {
            filterKey: key,
            removeFilters: this.removeFilters,
            header: filter.header,
            chartData: {
                data,
                filters: this.filters[key] || new Set(),
                range: [0, upperRange],
                events: [
                    { event: 'click', handler: e => this.handleFilterUpdate(e, categoryIdx, key, dim) }
                ]
            },
            isCollapsible: this.props.isCollapsible,
            isVisible: false,
            icon
        }

        return <FilterChart key={key} {...props} />
    }

    render() {
        const { filters } = this.props
        return (
            <div>
                { filters.map(({ label, icon, filters: categoryFilters }, categoryIdx) => (
                    label ? (
                        <Segment key={label} basic>
                            <a
                                onClick={() => this.toggleVisibility(categoryIdx)}
                                style={{ float: 'left', cursor: 'pointer' }}
                            >
                                {icon ? <Image src={icon} className="indicator-icon" size="mini" /> : null}
                                <Icon name={this.state.categoriesVisibility[categoryIdx] ? 'caret down' : 'caret right'} />
                            </a>
                            <div style={{ display: 'flex', position: 'absolute', left: '50px' }}>
                                <Header
                                    floated="left"
                                    onClick={() => this.toggleVisibility(categoryIdx)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    {label}
                                </Header>
                                {FILTERS_HELP[label] && <HelpModal label={label} />}
                            </div>
                            <Segment basic className={this.state.categoriesVisibility[categoryIdx] ? '' : 'hidden'}>
                                {Object.entries(categoryFilters).map(([key, filter], idx) => (
                                    this.renderFilterChart(categoryIdx, key, filter, idx)
                                ))}
                            </Segment>
                        </Segment>
                    ) : (
                        Object.entries(categoryFilters).map(([key, filter], idx) => (
                            this.renderFilterChart(categoryIdx, key, filter, idx, icon)
                        ))
                    )
                ))}
                <br />
                <Divider />
                <Segment inverted secondary color="red">
                    <b>Disclaimer:</b> Map displays only what was found at a select plot. Viewers should not make inferences of surrounding forest based on single plot estimates. Displayed plot locations are slightly moved from actual location to protect landowner confidentiality and to protect sample design from management bias.
                </Segment>
            </div>
        )
    }
}

FilterCharts.propTypes = FilterChartsPropTypes

FilterCharts.defaultProps = {
    filterUpdateCallback: null,
    isCollapsible: true
}

export default FilterCharts
