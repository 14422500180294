import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
    Grid,
    GridColumn,
    GridRow,
    Segment
} from 'semantic-ui-react'

import MapContext from './MapContext'
import Footer from './Footer'
import Header from './Header'
import { initMap } from './Map'
import Sidebar from './Sidebar'

class Layout extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            map: null
        }
    }

    componentDidMount() {
        if (!this.state.map) {
            this.setState({ map: initMap(this.context.store) })
        }
    }

    render() {
        const { isPageLoading } = this.props
        return (
            <Segment
                id="Layout"
                basic
                disabled={isPageLoading}
                loading={isPageLoading}
            >
                <Grid id="LayoutGrid">
                    <GridRow id="HeaderRow">
                        <GridColumn>
                            <Header />
                        </GridColumn>
                    </GridRow>
                    <GridRow id="ContentRow">
                        <GridColumn id="SidebarContainer">
                            {this.state.map ?
                                (
                                    <MapContext.Provider value={this.state.map}>
                                        <Sidebar />
                                    </MapContext.Provider>
                                ) :
                                null
                            }
                        </GridColumn>
                        <GridColumn id="MapContainer" />
                    </GridRow>
                    <GridRow id="FooterRow">
                        <GridColumn>
                            <Footer />
                        </GridColumn>
                    </GridRow>
                </Grid>
            </Segment>
        )
    }
}

Layout.contextTypes = {
    store: PropTypes.object.isRequired
}

Layout.propTypes = {
    // redux props
    isPageLoading: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
    isPageLoading: state.getIn(['page', 'isLoading'])
})

export default withRouter(connect(mapStateToProps)(Layout))
