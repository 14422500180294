import React from 'react'
import {
    Header, Icon, Segment, Image
} from 'semantic-ui-react'

import { FilterChartPropTypes } from '../../propTypes'
import VerticalBarChart from '../Visualizers/VerticalBarChart'
import FILTERS_HELP from '../../config/filtersHelp'
import HelpModal from './HelpModal'

class FilterChart extends React.Component {
    constructor(props) {
        super(props)

        const { isVisible, isCollapsible } = props
        this.state = {
            isVisible: isVisible || !isCollapsible
        }
    }

    toggleVisibility = () => {
        this.setState((state) => {
            state.isVisible = !state.isVisible
            return state
        })
    }

    render() {
        const {
            filterKey,
            header,
            chartData,
            isCollapsible,
            removeFilters,
            icon
        } = this.props
        const { isVisible } = this.state
        return (
            <React.Fragment>
                <Segment basic>
                    {isCollapsible ?
                        (
                            <a
                                onClick={this.toggleVisibility}
                                style={{ float: 'left', cursor: 'pointer' }}
                            >
                                {icon ? <Image src={icon} className="indicator-icon" size="mini" /> : null}
                                <Icon name={isVisible ? 'caret down' : 'caret right'} />
                            </a>
                        ) :
                        null
                    }
                    <div style={{ display: 'flex', position: 'absolute', left: '50px' }}>
                        <Header
                            floated="left"
                            onClick={isCollapsible && this.toggleVisibility}
                            style={{
                                cursor: isCollapsible ? 'pointer' : 'auto',
                                height: '1em'
                            }}
                        >
                            {header}
                        </Header>
                        {FILTERS_HELP[header] && <HelpModal label={header} />}
                    </div>
                </Segment>
                {chartData.filters.size ?
                    (
                        <a
                            onClick={() => removeFilters(filterKey)}
                            style={{ position: 'absolute', right: '4px', cursor: 'pointer' }}
                        >
                            <Icon
                                name="remove"
                                color="orange"
                                size="small"
                                inverted
                                circular
                            />
                        </a>
                    ) :
                    null
                }
                {isVisible ? <VerticalBarChart {...chartData} /> : null}
            </React.Fragment>
        )
    }
}

FilterChart.propTypes = FilterChartPropTypes

FilterChart.defaultProps = {
    isCollapsible: true,
    isVisible: false
}

export default FilterChart
