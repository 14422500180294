import { fromJS } from 'immutable'

import { data, DATA_STATE } from './data'
import { map, MAP_STATE } from './map'
import { page, PAGE_STATE } from './page'

const INITIAL_STATE = fromJS({
    data: DATA_STATE,
    map: MAP_STATE,
    page: PAGE_STATE
})

const reducers = {
    data,
    map,
    page
}

export { reducers, INITIAL_STATE }
