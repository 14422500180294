/* eslint-disable camelcase */
import React from 'react'
import { Map as L_Map } from 'leaflet'
import PropTypes from 'prop-types'
import { Segment } from 'semantic-ui-react'

import Indicators from '../../files/mariana.geojson'
import PAs from '../../files/mariana_pa.topojson'
import Profile from '../../images/mariana.jpg'
import IndicatorsSummary from '../../files/MCSummary_CNMI.pdf'

import Base from './Base'
import { OVERLAYS_ATTRS } from '../Map'

const Props = {
    viewName: 'mariana',
    header: 'Commonwealth of the Northern Mariana Islands',
    profileUrl: Profile,
    partners: [
        { label: 'CNMI Department of Lands and Natural Resources', url: 'https://opd.gov.mp/library/agency/department-of-lands-and-natural-resources/' },
        { label: 'CNMI Forestry', url: '' },
        { label: 'CNMI Bureau of Environmental and Coastal Quality', url: 'http://www.deq.gov.mp/sec.asp?secID=18' },
        { label: 'The Commonwealth of the Northern Mariana Islands’ Coral Reef Initiative', url: '' },
        { label: 'Micronesia Islands Alliance', url: 'https://www.minapacific.org/' },
        { label: 'Pacific Coastal Research and Planning', url: 'https://www.pacificcrp.org/' }
    ],
    summaryContent: (
        <Segment basic>
            <p>
                The forests of Rota, Tinian, and Saipan were systematically inventoried in 2004 and 2015 by the USDA Forest Service Forest Inventory and Analysis Program (FIA).
            </p>
            <p>
                 Fieldwork for this project was primarily conducted by foresters from the CNMI Forestry program with training and program management assistance from FIA foresters, the University of Guam, and the University of Hawaii. The data collected on these long-term research plots can be used to help meet the goals of the Micronesia Challenge.
            </p>
            <p>
                In 2015, 37 FIA field plots were visited in a variety of forest types. High-resolution satellite imagery also was used to map five broad classes of land cover: forest, nonforest vegetation, urban, barren, and inland water.
            </p>
            <p>
                CNMI is expanding the frequency and intensity of monitoring plots in identified conservation priority areas to further meet the goals laid out by the Micronesia Challenge Terrestrial Measures Working Group.
            </p>
            <p>
                More information on CNMI’s monitoring programs are available through these websites:
            </p>
            <ul className="summary-links">
                <li>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.fs.fed.us/pnw/rma/fia-topics/inventory-data/index.php"
                    >
                        The complete FIA database for CNMI
                    </a>
                </li>
            </ul>
        </Segment>
    ),
    indicatorsDataUrl: Indicators,
    indicatorsSummary: IndicatorsSummary,
    layers: [
        Object.assign({
            url: PAs,
            type: 'topojson'
        }, OVERLAYS_ATTRS.pa),
        OVERLAYS_ATTRS.veg('mariana')
    ]
}

const Mariana = props => (
    <div className="darken-mariana"><Base {...Props} map={props.map} /></div>
)

Mariana.propTypes = {
    // props
    map: PropTypes.instanceOf(L_Map).isRequired
}

export default Mariana
