import { fromJS } from 'immutable'

import { ACTIONS } from '../actions/map'

const MAP_STATE = {
    hash: new Date(),
    action: null,
    args: null
}

const map = (state, action) => {
    switch (action.type) {
        case ACTIONS.SEND_ACTION_TO_MAP:
            return fromJS({
                hash: action.hash,
                action: action.action,
                args: action.args
            })
        default:
            return state
    }
}

export { map, MAP_STATE }
