import React from 'react'

export default {
    'Plot Type': (
        <div>
            <p>
                FIA is an abbreviation of forest inventory analysis, which is a United
                States Forest Service based program designed to monitor the status and
                trends of forest resources across all US states, territories, and
                affiliated Pacific Islands.
            </p>
            <p>
                Micronesia Challenge (MC) refers to additional forest inventory analysis
                plots measured in conservation or special management areas of Guam,
                within conservation, special management, or previously unsampled islands
                in FSM, and on previously unsampled islands in RMI.
            </p>
        </div>
    ),
    'Forest Type': (
        <p>
            Forest land cover with similar composition or association of trees and
            other plants due to the impact and interaction of corresponding physical
            and biological factors. There are five forest communities occurring across
            Micronesia according to the FIA’s incorporation of definitions from&nbsp;
            <span style={{ textDecoration: 'underline' }}>The Vegetation of Tropical Pacific Islands</span>&nbsp;
            by Mueller-Dombois and Fosberg, 1998. The five communities are agroforest,
            lowland tropical rainforest, mangrove, montane rainforest, and strand or
            halophytic vegetation.
        </p>
    ),
    'Invasive Plant Species': (
        <p>
            Prior to each inventory, each regional forestry office provided a list of
            plant species known to be of concern in the area. Any observation of a
            plant species from the list was recorded, along with its estimated cover.
        </p>
    ),
    Disturbance: (
        <p>
            To be inventoried, the area affected by any disturbance must be at least 1
            acre in size, affect at least 25 percent of all trees in a stand, or 50
            percent of an individual species’ count, or affect at least 25 percent of
            the soil surface or understory vegetation. Human disturbance is defined as
            any disturbance that cannot otherwise be defined, and fire in Micronesia.
            Natural disturbances across Micronesia included weather, insects, animals,
            vegetation competition, and tree disease.
        </p>
    ),
    'Species Diversity': (
        <p>
            Diversity is defined here as species richness, or the total number of
            species per plot (0.07 hectares). Saplings are trees greater than 1-inch
            DBH (diameter at breast height (1.3 meters)) but less than 5 inches DBH,
            and trees are 5 inches DBH or greater.
        </p>
    ),
    'Forest Structure': (
        <div>
            <p>
                <strong>Number of Trees per Plot</strong> – The number of trees with a measured DBH
                (diameter at breast height (1.3 meters) of 5 inches or greater per plot
                (0.07 hectares).
            </p>
            <p>
                <strong>Number of Saplings per Plot</strong> – The number of trees with a measured DBH
                greater than 1 inch but less than 5 inches per plot.
            </p>
            <p>
                <strong>Tree Basal Area per Acre</strong> – The total sum of wood area from trees 5
                inches DBH or greater, in square feet at breast height (1.3 meters) per
                acre of forest land.
            </p>
            <p>
                <strong>Sapling Basal Area per Acre</strong> – The total sum of wood area from trees
                greater than 1 inch but less than 5 inches DBH, in square feet at breast
                height (1.3 meters) per acre of forest land.
            </p>
            <p>
                <strong>Tree Average Diameter</strong> – The average DBH (diameter at breast height (1.3
                meters)) per measured forest plot, of trees with 5 inches DBH or
                greater.
            </p>
            <p>
                <strong>Sapling Average Diameter</strong> - The average DBH (diameter at breast height
                (1.3 meters)) per measured forest plot, of saplings with DBH greater
                than 1 inch but less than 5 inches.
            </p>
            <p>
                <strong>Tree Average Height</strong> – The average height per measured forest plot of
                trees with DBH of 5 inches or greater.
            </p>
            <p>
                <strong>Sapling Average Height</strong> – The average height per measured forest plot of
                trees with DBH greater than 1 inch but less than 5 inches.
            </p>
        </div>
    ),
    'Forest Cover': (
        <p>Percent of measured forest plot area with live forest canopy cover.</p>
    )
}
