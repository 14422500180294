import React from 'react'
import L from 'leaflet'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {
    Container, Header, List, Segment, Button
} from 'semantic-ui-react'

import FSMData from '../../../files/fsm.geojson'
import IndicatorsSummary from '../../../files/MCSummary_FSM.pdf'

const MAP_PROPS = {
    layerAttrs: {
        options: {
            onEachFeature: (feature, layer) => {
                layer
                    .on('mouseover', () => layer.bindPopup(feature.properties.state).openPopup())
                    .on('mouseout', () => layer.closePopup())
                    .on('click', () => {
                        window.location.hash = `#/fsm/${feature.properties.id}`
                    })
            },
            style: (feature) => {
                switch (feature.properties.id) {
                    case 'chuuk':
                        return { fillColor: '#08298c', color: '#08298c', fillOpacity: 0.7 }
                    case 'kosrae':
                        return { fillColor: '#4d87ca', color: '#4d87ca', fillOpacity: 0.7 }
                    case 'pohnpei':
                        return { fillColor: '#0039ad', color: '#0039ad', fillOpacity: 0.7 }
                    case 'yap':
                        return { fillColor: '#006aa9', color: '#006aa9', fillOpacity: 0.7 }
                }
            }
        }
    }
}

class FSM extends React.Component {
    componentDidMount() {
        const xhr = new XMLHttpRequest()
        xhr.open('GET', FSMData)
        xhr.responseType = 'json'
        xhr.onload = () => {
            MAP_PROPS.name = 'fsm'
            MAP_PROPS.layerData = xhr.response
            this.props.map.updateIndicators(
                MAP_PROPS,
                {
                    updateBounds: true
                }
            )
        }
        xhr.send()
    }

    render() {
        return (
            <Container>
                <div className="darken-fsm">
                    <Segment basic clearing padded>
                        <Header size="large" floated="left" inverted>
                            Federated States of Micronesia
                            <Header.Subheader>
                                Chuuk, Kosrae, Pohnpei, and Yap
                            </Header.Subheader>
                        </Header>
                    </Segment>
                </div>
                <Segment basic style={{paddingTop: "0px"}}>
                    <Header size="medium" floated="left">
                        Select a state:
                    </Header>
                </Segment>
                <Segment basic>
                    <List bulleted relaxed>
                        <List.Item>
                            <Link to="/fsm/chuuk">
                                Chuuk
                            </Link>
                        </List.Item>
                        <List.Item>
                            <Link to="/fsm/kosrae">
                                Kosrae
                            </Link>
                        </List.Item>
                        <List.Item>
                            <Link to="/fsm/pohnpei">
                                Pohnpei
                            </Link>
                        </List.Item>
                        <List.Item>
                            <Link to="/fsm/yap">
                                Yap
                            </Link>
                        </List.Item>
                    </List>
                </Segment>
                <Segment basic style={{paddingTop: "0px"}}>
                    <p>
                        The USDA Forest Service Forest Inventory and Analysis (FIA) program conducted systematic inventories of the forests of Kosrae, Chuuk, Pohnpei, and Yap in the Federated States of Micronesia in 2005-2006 and 2016. Fieldwork for this project was primarily conducted by local foresters with training from the FIA foresters from the USDA Forest Service Pacific Northwest Research Station and the University of Guam.
                    </p>
                    <p>
                        After initial research plots were installed in 2005-2006, it was determined that expanding this dataset would help meet the region-wide goals of the Micronesia Challenge. Therefore, in 2016 the Micronesia Conservation Trust organized a regional effort to establish additional monitoring in the Federated States of Micronesia. A USDA Forest Service Landscape Restoration Grant provided funding for these additional plots.
                    </p>
                    <p>
                        In the 2016 inventory, 151 FIA plots were visited in the Federated States of Micronesia, 71 of which were Micronesia Challenge plots.
                    </p>
                </Segment>
                <Segment basic style={{paddingTop: "0px"}}>
                    <Header size="small" floated="left">
                        Partners in the Federated States of Micronesia:
                    </Header>
                </Segment>
                <Segment basic>
                    <List bulleted relaxed>
                        <List.Item>
                            <Link to="http://www.fsmrd.fm/">
                                FSM Department of Resources and Development
                            </Link>
                        </List.Item>
                        <List.Item>
                            <Link to="https://www.nature.org/ourinitiatives/regions/asiaandthepacific/pacific-islands/index.htm">
                                The Nature Conservancy
                            </Link>
                        </List.Item>
                        <List.Item>
                            <Link to="https://pacific-r2r.org/partners/member-countries/fsm">
                                Ridge to Reef
                            </Link>
                        </List.Item>
                    </List>
                </Segment>
                <Segment basic style={{paddingTop: "0px"}}>
                    <Header size="small" floated="left">
                        Summary of Indicators:
                    </Header>
                </Segment>
                <Segment basic>
                    <a href={IndicatorsSummary} target="_blank" rel="noopener noreferrer">
                        <Button>Summary PDF</Button>
                    </a>
                </Segment>
            </Container>
        )
    }
}

FSM.propTypes = {
    // props
    map: PropTypes.instanceOf(L.Map).isRequired
}

export default FSM
