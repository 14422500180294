/* eslint-disable camelcase */
import React from 'react'
import { Map as L_Map } from 'leaflet'
import PropTypes from 'prop-types'
import { Segment } from 'semantic-ui-react'

import Indicators from '../../files/guam.geojson'
import PAs from '../../files/guam_pa.topojson'
import Profile from '../../images/guam.jpg'
import IndicatorsSummary from '../../files/MCSummary_Guam.pdf'

import CONFIG from '../../config'
import { OVERLAYS_ATTRS } from '../Map'
import Base from './Base'

const Props = {
    viewName: 'guam',
    header: 'Guam',
    profileUrl: Profile,
    partners: [
        { label: 'The University of Guam, College of Natural and Applied Sciences', url: 'http://cnas-re.uog.edu/ ' },
        { label: 'Guam Department of Agriculture, Forestry and Soil Resources Division', url: 'http://doag.guam.gov/' },
        { label: 'Northern Guam Soil and Water Conservation District', url: '' },
        { label: 'Southern Guam Soil and Water Conservation District', ulr: '' },
        { label: 'Guam Plant Extinction Prevention Program', ulr: 'http://www.gpepp.org/' }
    ],
    summaryContent: (
        <Segment basic>
            <p>
                Guam has a large network of forested wildlife refuge conservation areas through a partnership with the U.S. Fish and Wildlife Service and the Department of Defense. The Government of Guam, Department of Agriculture has also set aside a priority areas for forest management which includes some of Guam’s southern watersheds.
            </p>
            <p>
                Guam has several ongoing forest monitoring projects. This summary is based on data collected by the USDA Forest Service Pacific Northwest Research Station Forest Inventory and Analysis Program (PNW FIA) in 2002 and 2013. PNW FIA worked with foresters from the Guam Department of Agriculture, Forestry and Soil Resources Division to establish permanent forest inventory plots to help monitor the island’s forests.
            </p>
            <p>
                Micronesia Challenge plots were added in the Guam National Wildlife Refuge in the north and various watersheds in the south that are a priority for monitoring changes in forest cover and forest health. Monitoring of terrestrial and marine resources is being accomplished through partnerships with the following entities:
            </p>
            <ul>
                <li>
                    Guam Department of Agriculture, Forestry and Soil Resources Division
                </li>
                <li>
                    University of Guam
                </li>
                <li>
                    USDA Forest Service Pacific Southwest Region State and Private Forestry
                </li>
                <li>
                    USDA Forest Service Pacific Southwest Research Station
                </li>
            </ul>
            <p>
                A team of trained local foresters remains in contact with PNW FIA staff and informed about regional FIA activities. The team is available to assist other Island nations with Micronesia Challenge terrestrial monitoring programs.
            </p>
            <p>
                In 2013, 133 FIA research plots were inventoried in Guam; 67 of which are Micronesia Challenge plots.
            </p>
            <p>
                More information on Guam’s monitoring programs are available through these websites:
            </p>
            <ul className="summary-links">
                <li>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.fs.fed.us/pnw/rma/fia-topics/inventory-data/index.php "
                    >
                        The complete Forest Inventory and Analysis database for Guam
                    </a>
                </li>
            </ul>
        </Segment>
    ),
    indicatorsDataUrl: Indicators,
    indicatorsSummary: IndicatorsSummary,
    layers: [
        Object.assign({
            url: PAs,
            type: 'topojson'
        }, OVERLAYS_ATTRS.pa),
        OVERLAYS_ATTRS.veg('guam')
    ]
}

const Guam = props => (
    <div className="darken-guam"><Base {...Props} map={props.map} /></div>
)

Guam.propTypes = {
    // props
    map: PropTypes.instanceOf(L_Map).isRequired
}

export default Guam
