import L from 'leaflet'

import { precision } from '../utils/formatters'
import { BREAKS } from './filters'

const ICONS = {
    FIA: L.divIcon({
        html: '<i class="marker big yellow icon"></i>',
        className: 'custom-marker',
        iconSize: new L.Point(40, 40)
    }),
    MC: L.divIcon({
        html: '<i class="marker big blue icon"></i>',
        className: 'custom-marker',
        iconSize: new L.Point(40, 40)
    })
}

const FOREST_COM_ICONS = {
    1: L.divIcon({
        html: '<i class="marker big purple icon"></i>',
        className: 'custom-marker',
        iconSize: new L.Point(40, 40)
    }),
    2: L.divIcon({
        html: '<i class="marker big blue icon"></i>',
        className: 'custom-marker',
        iconSize: new L.Point(40, 40)
    }),
    3: L.divIcon({
        html: '<i class="marker big orange icon"></i>',
        className: 'custom-marker',
        iconSize: new L.Point(40, 40)
    }),
    4: L.divIcon({
        html: '<i class="marker big yellow icon"></i>',
        className: 'custom-marker',
        iconSize: new L.Point(40, 40)
    }),
    8: L.divIcon({
        html: '<i class="marker big red icon"></i>',
        className: 'custom-marker',
        iconSize: new L.Point(40, 40)
    })
}

export default {
    padding: [0, 700],
    minZoom: 8,
    layerAttrs: {
        categoryLegend: {
            PLOTTYPE: {
                name: '',
                elements: [
                    {
                        label: 'FIA plot',
                        html: '<div class="custom-marker"><i class="marker big yellow icon"></i></div>',
                        style: {
                            width: '30px'
                        }
                    },
                    {
                        label: 'Micronesia Challenge plot',
                        html: '<div class="custom-marker"><i class="marker big blue icon"></i></div>',
                        style: {
                            width: '30px'
                        }
                    }
                ]
            },
            FOREST_COM: {
                name: '',
                elements: [
                    {
                        label: 'Strand or halophytic vegetation',
                        html: '<div class="custom-marker"><i class="marker big purple icon"></i></div>',
                        style: {
                            width: '30px'
                        }
                    },
                    {
                        label: 'Mangrove swamps',
                        html: '<div class="custom-marker"><i class="marker big blue icon"></i></div>',
                        style: {
                            width: '30px'
                        }
                    },
                    {
                        label: 'Lowland tropical rainforest',
                        html: '<div class="custom-marker"><i class="marker big orange icon"></i></div>',
                        style: {
                            width: '30px'
                        }
                    },
                    {
                        label: 'Montane Rainforest',
                        html: '<div class="custom-marker"><i class="marker big yellow icon"></i></div>',
                        style: {
                            width: '30px'
                        }
                    },
                    {
                        label: 'Agroforest',
                        html: '<div class="custom-marker"><i class="marker big red icon"></i></div>',
                        style: {
                            width: '30px'
                        }
                    }
                ]
            },
            PERCINVS_1: {
                name: 'Invasive Plant Species',
                elements: BREAKS.PERCINVS_1.map((br, idx) => {
                    if (idx === 0) {
                        return {
                            label: 'No invasive plant species',
                            html: `<div class="custom-marker"><i class="big marker icon q${idx + 1}-8 reds"></i></div>`
                        }
                    } if (idx === 1) {
                        return {
                            label: '1 - 10% area cover',
                            html: `<div class="custom-marker"><i class="big marker icon q${idx + 1}-8 reds"></i></div>`
                        }
                    }
                    return {
                        label: `${BREAKS.PERCINVS_1[idx - 1]}-${br}% area cover`,
                        html: `<div class="custom-marker"><i class="big marker icon q${idx + 1}-8 reds"></i></div>`
                    }
                })
            },
            HD_Total: {
                name: 'Human disturbance',
                elements: BREAKS.HD_Total.map((br, idx) => {
                    if (idx === 0) {
                        return {
                            label: 'No Disturbance',
                            html: `<div class="custom-marker"><i class="big marker icon q${idx + 1}-8 reds"></i></div>`
                        }
                    }
                    if (idx === 0) {
                        return {
                            label: `${BREAKS.HD_Total[idx]} type of human disturbance`,
                            html: `<div class="custom-marker"><i class="big marker icon q${idx + 1}-8 reds"></i></div>`
                        }
                    }
                    return {
                        label: `${BREAKS.HD_Total[idx]} types of human disturbance`,
                        html: `<div class="custom-marker"><i class="big marker icon q${idx + 1}-8 reds"></i></div>`
                    }
                })
            },
            ND_Total: {
                name: 'Human disturbance',
                elements: BREAKS.ND_Total.map((br, idx) => {
                    if (idx === 0) {
                        return {
                            label: 'No Disturbance',
                            html: `<div class="custom-marker"><i class="big marker icon q${idx + 1}-8 reds"></i></div>`
                        }
                    }
                    if (idx === 1) {
                        return {
                            label: `${BREAKS.ND_Total[idx]} type of natural disturbance`,
                            html: `<div class="custom-marker"><i class="big marker icon q${idx + 1}-8 reds"></i></div>`
                        }
                    }
                    return {
                        label: `${BREAKS.ND_Total[idx]} types of natural disturbance`,
                        html: `<div class="custom-marker"><i class="big marker icon q${idx + 1}-8 reds"></i></div>`
                    }
                })
            },
            F_SapSpeci: {
                name: 'Sapling species per plot',
                elements: BREAKS.F_SapSpeci.slice(1).map((br, idx) => ({
                    label: `${BREAKS.F_SapSpeci[idx]}-${BREAKS.F_SapSpeci[idx + 1] - 1}`,
                    html: `<div class="custom-marker"><i class="big marker icon q${idx}-8 reds"></i></div>`
                }))
            },
            F_TreeSpec: {
                name: 'Tree species per plot',
                elements: BREAKS.F_TreeSpec.slice(1).map((br, idx) => ({
                    label: `${BREAKS.F_TreeSpec[idx]}-${BREAKS.F_TreeSpec[idx + 1] - 1}`,
                    html: `<div class="custom-marker"><i class="big marker icon q${idx}-8 reds"></i></div>`
                }))
            },
            F_TotalSpe: {
                name: 'Total species per plot',
                elements: BREAKS.F_TotalSpe.slice(1).map((br, idx) => ({
                    label: `${BREAKS.F_TotalSpe[idx]}-${BREAKS.F_TotalSpe[idx + 1] - 1}`,
                    html: `<div class="custom-marker"><i class="big marker icon q${idx}-8 reds"></i></div>`
                }))
            },
            F_Treesper: {
                name: 'Trees per plot',
                elements: BREAKS.F_Treesper.map((br, idx) => {
                    if (idx === 0) {
                        return {
                            label: 'No trees',
                            html: `<div class="custom-marker"><i class="big marker icon q${idx + 1}-8 reds"></i></div>`
                        }
                    } if (idx === 1) {
                        return {
                            label: `1-${br} trees`,
                            html: `<div class="custom-marker"><i class="big marker icon q${idx + 1}-8 reds"></i></div>`
                        }
                    }
                    return {
                        label: `${BREAKS.F_Treesper[idx - 1] + 1}-${br} trees`,
                        html: `<div class="custom-marker"><i class="big marker icon q${idx + 1}-8 reds"></i></div>`
                    }
                })
            },
            F_Saplings: {
                name: 'Saplings per plot',
                elements: BREAKS.F_Saplings.map((br, idx) => {
                    if (idx === 0) {
                        return {
                            label: 'No saplings',
                            html: `<div class="custom-marker"><i class="big marker icon q${idx + 1}-8 reds"></i></div>`
                        }
                    } if (idx === 1) {
                        return {
                            label: `1-${br} saplings`,
                            html: `<div class="custom-marker"><i class="big marker icon q${idx + 1}-8 reds"></i></div>`
                        }
                    }
                    return {
                        label: `${BREAKS.F_Saplings[idx - 1] + 1}-${br} saplings`,
                        html: `<div class="custom-marker"><i class="big marker icon q${idx + 1}-8 reds"></i></div>`
                    }
                })
            },
            TREE_BAPER: {
                name: 'Basal area per acre',
                elements: BREAKS.TREE_BAPER.map((br, idx) => {
                    if (idx === 0) {
                        return {
                            label: 'No trees',
                            html: `<div class="custom-marker"><i class="big marker icon q${idx + 1}-8 reds"></i></div>`
                        }
                    } if (idx === 1) {
                        return {
                            label: `1-${br} sq ft`,
                            html: `<div class="custom-marker"><i class="big marker icon q${idx + 1}-8 reds"></i></div>`
                        }
                    }
                    return {
                        label: `${BREAKS.TREE_BAPER[idx - 1] + 1}-${br} sq ft`,
                        html: `<div class="custom-marker"><i class="big marker icon q${idx + 1}-8 reds"></i></div>`
                    }
                })
            },
            SAP_BAPERA: {
                name: 'Basal area per acre',
                elements: BREAKS.SAP_BAPERA.map((br, idx) => {
                    if (idx === 0) {
                        return {
                            label: 'No saplings',
                            html: `<div class="custom-marker"><i class="big marker icon q${idx + 1}-8 reds"></i></div>`
                        }
                    } if (idx === 1) {
                        return {
                            label: `1-${br} sq ft`,
                            html: `<div class="custom-marker"><i class="big marker icon q${idx + 1}-8 reds"></i></div>`
                        }
                    }
                    return {
                        label: `${BREAKS.SAP_BAPERA[idx - 1] + 1}-${br} sq ft`,
                        html: `<div class="custom-marker"><i class="big marker icon q${idx + 1}-8 reds"></i></div>`
                    }
                })
            },
            TREE_AVEDI: {
                name: 'Tree average redseter',
                elements: BREAKS.TREE_AVEDI.map((br, idx) => {
                    if (idx === 0) {
                        return {
                            label: 'Not applicable (no trees)',
                            html: `<div class="custom-marker"><i class="big marker icon q${idx + 1}-8 reds"></i></div>`
                        }
                    } if (idx === 1) {
                        return {
                            label: `1-${br} inches`,
                            html: `<div class="custom-marker"><i class="big marker icon q${idx + 1}-8 reds"></i></div>`
                        }
                    }
                    return {
                        label: `${BREAKS.TREE_AVEDI[idx - 1] + 1}-${br} inches`,
                        html: `<div class="custom-marker"><i class="big marker icon q${idx + 1}-8 reds"></i></div>`
                    }
                })
            },
            SAP_AVEDIA: {
                name: 'Sapling average redseter',
                elements: BREAKS.SAP_AVEDIA.map((br, idx) => {
                    if (idx === 0) {
                        return {
                            label: 'Not applicable (no saplings)',
                            html: `<div class="custom-marker"><i class="big marker icon q${idx + 1}-8 reds"></i></div>`
                        }
                    } if (idx === 1) {
                        return {
                            label: `${br} inch`,
                            html: `<div class="custom-marker"><i class="big marker icon q${idx + 1}-8 reds"></i></div>`
                        }
                    } if (idx === 2 || idx === 3) {
                        return {
                            label: `${br} inches`,
                            html: `<div class="custom-marker"><i class="big marker icon q${idx + 1}-8 reds"></i></div>`
                        }
                    }
                    return {
                        label: `${BREAKS.SAP_AVEDIA[idx - 1] + 1}-${br} inches`,
                        html: `<div class="custom-marker"><i class="big marker icon q${idx + 1}-8 reds"></i></div>`
                    }
                })
            },
            TREE_AVEHT: {
                name: 'Tree average height',
                elements: BREAKS.TREE_AVEHT.map((br, idx) => {
                    if (idx === 0) {
                        return {
                            label: 'Not applicable (no trees)',
                            html: `<div class="custom-marker"><i class="big marker icon q${idx + 1}-8 reds"></i></div>`
                        }
                    } if (idx === 1) {
                        return {
                            label: `1-${br} feet`,
                            html: `<div class="custom-marker"><i class="big marker icon q${idx + 1}-8 reds"></i></div>`
                        }
                    }
                    return {
                        label: `${BREAKS.TREE_AVEHT[idx - 1] + 1}-${br} feet`,
                        html: `<div class="custom-marker"><i class="big marker icon q${idx + 1}-8 reds"></i></div>`
                    }
                })
            },
            SAP_AVEHT: {
                name: 'Tree average height',
                elements: BREAKS.SAP_AVEHT.map((br, idx) => {
                    if (idx === 0) {
                        return {
                            label: 'Not applicable (no saplings)',
                            html: `<div class="custom-marker"><i class="big marker icon q${idx + 1}-8 reds"></i></div>`
                        }
                    } if (idx === 1) {
                        return {
                            label: `1-${br} inches`,
                            html: `<div class="custom-marker"><i class="big marker icon q${idx + 1}-8 reds"></i></div>`
                        }
                    }
                    return {
                        label: `${BREAKS.SAP_AVEHT[idx - 1] + 1}-${br} inches`,
                        html: `<div class="custom-marker"><i class="big marker icon q${idx + 1}-8 reds"></i></div>`
                    }
                })
            },
            ForestCove: {
                name: 'Forest cover',
                elements: BREAKS.ForestCove.slice(1).map((br, idx) => ({
                    label: `${BREAKS.ForestCove[idx]}-${br}%`,
                    html: `<div class="custom-marker"><i class="big marker icon q${idx}-8 reds"></i></div>`
                }))
            }
        },
        defaultCategory: 'PLOTTYPE',
        style: (layer, category) => {
            const value = layer.feature.properties[category]

            const getBreakIcon = (classes) => {
                let cls = BREAKS[category].length
                for (let i = 0; i < BREAKS[category].length; i++) {
                    if (value < BREAKS[category][i]) {
                        cls = i
                        break
                    }
                }
                return L.divIcon({
                    html: `<i class="big marker icon q${cls - 1}-${classes}"></i>`,
                    className: 'custom-marker',
                    iconSize: new L.Point(40, 40)
                })
            }

            const getBreakIconInclusive = (classes) => {
                let cls = BREAKS[category].length
                for (let i = 0; i < BREAKS[category].length; i++) {
                    if (value <= BREAKS[category][i]) {
                        cls = i
                        break
                    }
                }
                return L.divIcon({
                    html: `<i class="big marker icon q${cls + 1}-${classes}"></i>`,
                    className: 'custom-marker',
                    iconSize: new L.Point(40, 40)
                })
            }

            switch (category) {
                case 'PLOTTYPE':
                    layer.setIcon(ICONS[value])
                    break
                case 'FOREST_COM':
                    layer.setIcon(FOREST_COM_ICONS[value])
                    break
                case 'PERCINVS_1':
                case 'F_Treesper':
                case 'F_Saplings':
                case 'HD_Total':
                case 'ND_Total':
                case 'TREE_AVEDI':
                case 'SAP_AVEDIA':
                case 'SAP_BAPERA':
                case 'TREE_BAPER':
                case 'TREE_AVEHT':
                case 'SAP_AVEHT':
                    layer.setIcon(getBreakIconInclusive('8 reds'))
                    break
                case 'F_SapSpeci':
                case 'F_TreeSpec':
                case 'F_TotalSpe':
                case 'ForestCove':
                    layer.setIcon(getBreakIcon('8 reds'))
                    break
                    /* eslint-disable-next-line no-case-declarations */
                    const percent = value === '1' ? 99.9 : (value || 0) * 100
                    layer.setIcon(L.divIcon({
                        html: `<div class="invasiveSpecies pie" style="animation-delay: -${percent}s"><span>${precision(value * 100, 0)}%</span></div>`,
                        className: 'custom-marker',
                        iconSize: new L.Point(40, 40)
                    }))
                    break
            }
        }
    }
}
