/* eslint-disable camelcase */
import React from 'react'
import { Map as L_Map } from 'leaflet'
import PropTypes from 'prop-types'
import { Segment } from 'semantic-ui-react'

import Indicators from '../../../files/fsm_chuuk.geojson'
import PAs from '../../../files/fsm_chuuk_pa.topojson'
import Profile from '../../../images/chuuk.jpg'
import IndicatorsSummary from '../../../files/MCSummary_Chuuk.pdf'

import Base from '../Base'
import { OVERLAYS_ATTRS } from '../../Map'

const Props = {
    viewName: 'chuuk',
    header: 'Chuuk',
    backUrl: {
        url: '/fsm',
        label: 'Back to FSM overview'
    },
    profileUrl: Profile,
    partners: [
        { label: 'Chuuk Conservation Society', url: '' },
        { label: 'Chuuk State Forestry', url: '' }
    ],
    summaryContent: (
        <Segment basic>
            <p>
                Prior to the forest inventory in Chuuk, representatives from the state and local organizations identified areas in the Chuuk where Forest Inventory and Analysis plots would be most beneficial to the state. They prioritized plots that landed in protected areas.
            </p>
            <p>
                In 2016, field crew inventoried 22 FIA plots:
            </p>
            <ul>
                <li>
                    10 FIA grid plots
                </li>
                <li>
                    2 Micronesia Challenge plots
                </li>
            </ul>
            <br />
            <p>
                More information on Chuuk’s monitoring programs are available through these websites:
            </p>
            <ul className="summary-links">
                <li>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.fs.fed.us/pnw/rma/fia-topics/inventory-data/index.php"
                    >
                        The complete Forest Inventory and Analysis database for Federated States of Micronesia
                    </a>
                </li>
                <br />
                <li>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="http://www.comfsm.fm/bchm/TerrestrialBio.pdf"
                    >
                        Terrestrial Biodiversity of the FSM
                    </a>
                </li>
                <br />
                <li>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="http://www.reefresilience.org/case-studies/micronesia-mpa-design/"
                    >
                        Designing a Protected Area Network in FSM
                    </a>
                </li>
            </ul>
        </Segment>
    ),
    indicatorsDataUrl: Indicators,
    indicatorsSummary: IndicatorsSummary,
    layers: [
        Object.assign({
            url: PAs,
            type: 'topojson'
        }, OVERLAYS_ATTRS.pa),
        OVERLAYS_ATTRS.veg('chuuk')
    ]
}

const Chuuk = props => (
    <div className="darken-chuuk"><Base {...Props} map={props.map} /></div>
)

Chuuk.propTypes = {
    // props
    map: PropTypes.instanceOf(L_Map).isRequired
}

export default Chuuk
