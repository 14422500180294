/* eslint-disable camelcase */
import React from 'react'
import { Map as L_Map } from 'leaflet'
import PropTypes from 'prop-types'
import { Segment } from 'semantic-ui-react'

import Indicators from '../../../files/fsm_pohnpei.geojson'
import PAs from '../../../files/fsm_pohnpei_pa.geojson'
import Profile from '../../../images/pohnpei.jpg'
import IndicatorsSummary from '../../../files/MCSummary_Pohnpei.pdf'

import Base from '../Base'
import { OVERLAYS_ATTRS } from '../../Map'

const Props = {
    viewName: 'pohnpei',
    header: 'Pohnpei',
    backUrl: {
        url: '/fsm',
        label: 'Back to FSM overview'
    },
    profileUrl: Profile,
    partners: [
        { label: 'Pohnpei State Forestry', url: '' },
        { label: 'Conservation Society of Pohnpei', url: '' }
    ],
    summaryContent: (
        <Segment basic>
            <p>
                Prior to data collection in Pohnpei, a working group of state and local organizations identified which conservation areas would be selected as part of the regional Micronesia Challenge monitoring program and FIA plots were added in these priority areas. This group decided to set up equal representation in mangrove forests, upland forests, and strand forest found on the outer islands.
            </p>
            <p>
                In 2016 Pohnpei inventoried 54 FIA plots:
            </p>
            <ul>
                <li>
                    40 are FIA grid plots
                </li>
                <li>
                    14 are additional Micronesia Challenge plots
                </li>
            </ul>
            <br />
            <p>
                More information on Pohnpei’s monitoring programs are available through these websites:
            </p>
            <ul className="summary-links">
                <li>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.fs.fed.us/pnw/rma/fia-topics/inventory-data/index.php"
                    >
                        The complete Forest Inventory and Analysis database for the Federated States of Micronesia
                    </a>
                </li>
                <br />
                <li>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://eros.usgs.gov/doi-remote-sensing-activities/2016/mangrove-monitoring-and-carbon-assessment"
                    >
                        Information on Pohnpei’s Mangrove Monitoring and Carbon assessment
                    </a>
                </li>
                <br />
                <li>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="http://www.comfsm.fm/bchm/TerrestrialBio.pdf"
                    >
                        Terrestrial Biodiversity of the FSM
                    </a>
                </li>
                <br />
                <li>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="http://www.reefresilience.org/case-studies/micronesia-mpa-design/"
                    >
                        Designing a Protected Area Network in FSM
                    </a>
                </li>
            </ul>
        </Segment>
    ),
    indicatorsDataUrl: Indicators,
    indicatorsSummary: IndicatorsSummary,
    layers: [
        Object.assign({
            url: PAs,
            type: 'geojson'
        }, OVERLAYS_ATTRS.pa),
        OVERLAYS_ATTRS.veg('pohnpei')
    ]
}

const Pohnpei = props => (
    <div className="darken-pohnpei"><Base {...Props} map={props.map} /></div>
)

Pohnpei.propTypes = {
    // props
    map: PropTypes.instanceOf(L_Map).isRequired
}

export default Pohnpei
