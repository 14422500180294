import React from 'react'
import L from 'leaflet'
import PropTypes from 'prop-types'
import {
    Container, Header, Segment, Image
} from 'semantic-ui-react'

import IslandsMarkers from '../../files/islands.geojson'
import Marshall from '../../images/MarshallSeal.png'
import Mariana from '../../images/MarianaSeal.jpg'
import Guam from '../../images/GuamSeal.png'
import FSM from '../../images/FSMSeal.png'
import Palau from '../../images/PalauSeal.jpg'

const MAP_PROPS = {
    layerAttrs: {
        options: {
            onEachFeature: (feature, layer) => {
                layer
                    .on('mouseover', () => layer.bindPopup(feature.properties.name).openPopup())
                    .on('mouseout', () => layer.closePopup())
                    .on('click', () => {
                        window.location.hash = `#/${feature.properties.id}`
                    })
            },
            style: (feature) => {
                switch (feature.properties.id) {
                    case 'fsm':
                        return { fillColor: '#006aa9', color: '#006aa9', fillOpacity: 0.7 }
                    case 'guam':
                        return { fillColor: '#08298c', color: '#08298c', fillOpacity: 0.7 }
                    case 'mariana':
                        return { fillColor: '#4d87ca', color: '#4d87ca', fillOpacity: 0.7 }
                    case 'marshall':
                        return { fillColor: '#0039ad', color: '#0039ad', fillOpacity: 0.7 }
                    case 'palau':
                        return { fillColor: '#3E6CA2', color: '#3E6CA2', fillOpacity: 0.7 }
                }
            }
        }
    }
}

class Intro extends React.Component {
    componentDidMount() {
        this.props.map.overlaysGroup.clearLayers()
        this.props.map.indicatorsLayerGroup.clearLayers()

        const xhr = new XMLHttpRequest()
        xhr.open('GET', IslandsMarkers)
        xhr.responseType = 'json'
        xhr.onload = () => {
            MAP_PROPS.name = 'intro'
            MAP_PROPS.layerData = xhr.response
            this.props.map.updateIndicators(
                MAP_PROPS,
                { updateBounds: true }
            )
        }
        xhr.send()
    }

    render() {
        return (
            <Container>
                <div className="darken">
                    <Segment basic clearing padded>
                        <Header size="large" floated="left" inverted>
                        Micronesia Challenge
                            <Header.Subheader>
                            Regional Terrestrial Monitoring Initiative
                            </Header.Subheader>
                        </Header>
                    </Segment>
                </div>
                <Header>
                    Our Goal
                </Header>
                <div>
                    <p>
                        Provide a regional framework to assess the monitoring indicators that measure the status of managed conservation areas set aside under the Micronesia Challenge.
                    </p>
                    <p>
                        The Micronesia Challenge is a commitment by the Republic of Palau, Guam, the Commonwealth of the Northern Mariana Islands, the Federated States of Micronesia, and the Republic of the Marshall Islands to preserve the marine and terrestrial resources that are crucial to the survival of Pacific traditions, cultures and livelihoods.
                    </p>
                    <p>
                        The USDA Forest Service Forest Inventory and Analysis (FIA) program supports this effort by
                        working with these nations and partners to inventory and monitor the islands’ forest resources.
                        Forest data were collected between 2003 and 2018 and are now being used to determine the
                        status and trends in forest area, forest health, understory vegetation, biomass, and carbon
                        storage.
                    </p>
                </div>
                <Header>
                    Get Started
                </Header>
                <div>
                    This tool allows you to see our monitoring progress and learn more about the monitoring data collected across the region. Click on a country/territory for more information.
                </div>
                <Header>
                    Regional Partners
                </Header>
                <ul>
                    <li>
                        <a href="http://ourmicronesia.org/" target="_blank" rel="noopener noreferrer">
                            The Micronesia Conservation Trust
                        </a>
                    </li>
                    <li>
                        <a href="https://www.fs.fed.us/" target="_blank" rel="noopener noreferrer">
                            U.S. Forest Service
                        </a>
                    </li>
                    <li>
                        <a href="https://www.uog.edu/" target="_blank" rel="noopener noreferrer">
                            University of Guam
                        </a>
                    </li>
                    <li>
                        <a href="http://www.comfsm.fm/" target="_blank" rel="noopener noreferrer">
                            College of Micronesia
                        </a>
                    </li>
                    <li>
                        <a href="https://www.nature.org/" target="_blank" rel="noopener noreferrer">
                            The Nature Conservancy
                        </a>
                    </li>
                    <li>
                        <a href="https://consbio.org" target="_blank" rel="noopener noreferrer">
                            Conservation Biology Institute
                        </a>
                    </li>
                </ul>
                <div>
                    This tool was funded by the USDA Forest Service and regional partners.
                </div>
                <br />
                <Image.Group size="tiny">
                    <Image src={Palau} />
                    <Image src={Guam} />
                    <Image src={Mariana} />
                    <Image src={FSM} />
                    <Image src={Marshall} />
                </Image.Group>
                <br/>
                <div className="disclaimer">
                    <p>
                        In accordance with Federal civil rights law and U.S. Department of Agriculture (USDA) civil rights regulations and policies, the USDA, its Agencies, offices, and employees, and institutions participating in or administering USDA programs are prohibited from discriminating based on race, color, national origin, religion, sex, gender identity (including gender expression), sexual orientation, disability, age, marital status, family/parental status, income derived from a public assistance program, political beliefs, or reprisal or retaliation for prior civil rights activity, in any program or activity conducted or funded by USDA (not all bases apply to all programs).  Remedies and complaint filing deadlines vary by program or incident.
                    </p>
                    <p>
                        Persons with disabilities who require alternative means of communication for program information (e.g., Braille, large print, audiotape, American Sign Language, etc.) should contact the responsible Agency or USDA’s TARGET Center at (202) 720-2600 (voice and TTY) or contact USDA through the Federal Relay Service at (800) 877-8339. Additionally, program information may be made available in languages other than English.
                    </p>
                    <p>
                        To file a program discrimination complaint, complete the USDA Program Discrimination Complaint Form, AD-3027, found online at <span><a href="http://www.ascr.usda.gov/complaint_filing_cust.html" target="_blank" rel="noopener noreferrer">http://www.ascr.usda.gov/complaint_filing_cust.html</a></span> and at any USDA office or write a letter addressed to USDA and provide in the letter all of the information requested in the form. To request a copy of the complaint form, call (866) 632-9992. Submit your completed form or letter to USDA by: (1) mail: U.S. Department of Agriculture, Office of the Assistant Secretary for Civil Rights, 1400 Independence Avenue, SW, Washington, D.C. 20250-9410; (2) fax: (202) 690-7442; or (3) email:  <span><a href="mailto:program.intake@usda.gov">program.intake@usda.gov</a></span>.
                    </p>
                    <p>
                        USDA is an equal opportunity provider, employer, and lender.
                    </p>
                </div>
            </Container>
        )
    }
}

Intro.propTypes = {
    // props
    map: PropTypes.instanceOf(L.Map).isRequired
}

export default Intro
