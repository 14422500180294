import PropTypes from 'prop-types'

export const VerticalBarChartPropTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.number,
        valueLabel: PropTypes.string,
        key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        keyLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        color: PropTypes.string,
        filteredColor: PropTypes.string
    })).isRequired,
    filters: PropTypes.instanceOf(Set),
    range: PropTypes.arrayOf(PropTypes.number).isRequired,
    barHeight: PropTypes.number,
    showBasebar: PropTypes.bool,
    ignoreValueLabel: PropTypes.bool,
    margin: PropTypes.number,
    width: PropTypes.number,
    gap: PropTypes.number,
    labelPosDistance: PropTypes.number,
    events: PropTypes.arrayOf(PropTypes.shape({
        event: PropTypes.string,
        handler: PropTypes.func
    }))
}

export const FilterChartPropTypes = {
    filterKey: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    chartData: PropTypes.shape(VerticalBarChartPropTypes).isRequired,
    removeFilters: PropTypes.func.isRequired,
    isCollapsible: PropTypes.bool,
    isVisible: PropTypes.bool
}

export const GeoJSONFeaturesProps = PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string,
    properties: PropTypes.shape({}),
    geometry: PropTypes.shape({
        type: PropTypes.string,
        coordinates: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.array]))
    })
}))

export const FilterChartsPropTypes = {
    features: GeoJSONFeaturesProps,
    filters: PropTypes.arrayOf(PropTypes.shape({})),
    filterUpdateCallback: PropTypes.func,
    isCollapsible: PropTypes.bool
}
